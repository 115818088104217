import React, { useState } from 'react';
import { TextField, Button, Box, Select, MenuItem, FormControl, InputLabel, Grid, Link, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CountrySelect from './CountrySelect'; // Assuming you have a CountrySelect component
import { trackRegistration } from '../tracking';
import ReactGA from 'react-ga4';
import { loadGoogleAnalytics, loadMicrosoftClarity } from '../pages/App.js';

function Register() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [educationLevel, setEducationLevel] = useState('');
    const [degree, setDegree] = useState('');
    const [year, setYear] = useState('');
    const [university, setUniversity] = useState('');
    const [country, setCountry] = useState('');
    //const [inviteCode, setInviteCode] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
     //dateOfBirth === '' ? null : dateOfBirth,
     //ar === '' ? null : parseInt(year, 10),
     //educationLevel === 'bachelor' || educationLevel === 'master' ? university : null,
        const user = {
          email,
          password,
          date_of_birth: null, 
          education_level: '' , 
          degree: '', 
          year:null,
          university: null, 
          country: null,
          // invite_code: inviteCode
        };
    
        console.log('user data', user);
    
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}accounts/register`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
          });
    
          const data = await response.json();
          console.log(data);
    
          if (response.status === 201) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('userId', data.user_id); // Storing the user ID
            ReactGA.initialize('G-5MRSDQ2VCN');
          //ReactGA.initialize('G-5MRSDQ2VCN');  IMPORTANT - some information wont be transmitted until logged in. Not sure which
            localStorage.setItem('cookieConsent', 'true');
            loadMicrosoftClarity();
            loadGoogleAnalytics();
            alert("Registration successful");
            trackRegistration();
            navigate("/my-materials"); // Redirect after registration
          } else {
            alert("Registration failed", data);
            // Error handling
          }
        } catch (error) {
          console.error("Error during registration", error);
          alert("Registration failed");
        }
      };
    

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ '& .MuiFormControl-root': { m: 1 }, display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center' }}>
            {/*<Grid container spacing={2}>*/}
                {/*<Grid item xs={12} sm={6}>*/}
                <Box sx = {{display: 'flex', flexDirection: 'column'}}>
                    <TextField
                        label="Email"
                        variant="filled"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        fullWidth
                        sx={{ width: '162px','& .MuiInputBase-input': {} }}
                        />
                    <TextField
                        label="Password"
                        variant="filled"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        fullWidth
                        sx={{ width: '162px','& .MuiInputBase-input': {} }}
                    />
                  {/* <TextField
    label="Invite Code"
    variant="outlined"
    value={inviteCode}
    onChange={(e) => setInviteCode(e.target.value)}
    required
    fullWidth
    sx={{ '& .MuiInputBase-input': { backgroundColor: 'white' } }}
/> */}
                    {/*<TextField
                        label="Date of Birth"
                        variant="filled"
                        type="date"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ '& .MuiInputBase-input': { backgroundColor: 'white' } }}
                    />*}
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{ '& .MuiInputBase-input': { backgroundColor: 'white' } }} >
                        <InputLabel id="education-level-label">Education Level</InputLabel>
                        <Select
                            labelId="education-level-label"
                            value={educationLevel}
                            label="Education Level"
                            onChange={(e) => setEducationLevel(e.target.value)}
                        >   
                            <MenuItem value="elementary">Elementary School</MenuItem>
                            <MenuItem value="middle">Middle School</MenuItem>
                            <MenuItem value="high">High School</MenuItem>
                            <MenuItem value="bachelor">Bachelor’s Degree</MenuItem>
                            <MenuItem value="master">Master’s Degree</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Degree"
                        variant="filled"
                        value={degree}
                        onChange={(e) => setDegree(e.target.value)}
                        fullWidth
                        sx={{ '& .MuiInputBase-input': { backgroundColor: 'white' } }}
                    />
                    <TextField
                        label="Year/Class"
                        variant="filled"
                        type="number"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        fullWidth
                        sx={{ '& .MuiInputBase-input': { backgroundColor: 'white' } }}
                    />
                    {(educationLevel === 'bachelor' || educationLevel === 'master') && (
                        <TextField
                            label="University"
                            variant="filled"
                            value={university}
                            onChange={(e) => setUniversity(e.target.value)}
                            fullWidth
                            sx={{ '& .MuiInputBase-input': { backgroundColor: 'white' } }}
                        />
                    )}
                    {/*<CountrySelect
  value={country}
  onChange={(selectedCountry) => setCountry(selectedCountry ? selectedCountry.value : '')}
  placeholder="Country"
                />*/}
                
            
            </Box>
            <Box textAlign="center" sx={{ mt: 2 }}>
                <Box sx={{color: '#004065'}}>
                    By registering, you agree to the
                    <Link href="#" onClick={() => navigate('/privacy-policy')} sx={{ ml: 1 }}>Privacy Policy</Link> and<Link href="#" onClick={() => navigate('/terms-of-use')} sx={{ ml: 1 }}>Terms of Use</Link>.
                </Box>
                <Button type="submit" variant="contained"  sx={{ background: 'linear-gradient(45deg, #006547, #004065)',width: '162px', boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)', // Multiple shadows for even effect
        '&:hover': {
          background: 'linear-gradient(45deg, #006547, #004065)', // Maintain the gradient background on hover
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)', // Glowing effect with multiple shadows
        }, mt: 2 }}>
                    Register
                </Button>
                <Box sx={{color: '#004065',width: '100%',
        '@media (min-width:400px)': {
          width: '400px',
        }, fontSize: '12px', marginTop: '10px'}}>
                Please note that while our app is currently free, we reserve the right to introduce a premium version, which may limit some functionalities of the free version in the future.
                </Box>
            </Box>
        </Box>
    );
}

export default Register;


