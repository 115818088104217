import React from 'react';
import { Box, Link, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box component="footer" sx={{ background: 'linear-gradient(45deg, #006547, #004065)',  color: 'white', py: 2 }}>
      <Container maxWidth="lg">
        <Typography variant="body1" align="center" gutterBottom sx = {{marginBottom:2}}>
          LearnNavigator.io | Michal Cherczynski | Grasmeierstrasse 9, 80805 Munich | Email: learnnavigator@gmail.com
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
          <Link href="/privacy-policy" color="inherit" underline="hover">
            Privacy Policy
          </Link>
          {/* Increased margin on the left and right of the contact info */}
          <Link href="/terms-of-use" color="inherit" underline="hover">
            Terms of Use
          </Link>
          <Link href="/cookie-policy" color="inherit" underline="hover">
            Cookie Policy
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
