import React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; // Plus icon
import './AddMaterialButton.css'; // Import the CSS module

const AddMaterialButton = ({ onClick }) => {
    return (
      <ButtonBase
        onClick={onClick}
        className="addMaterialButton"
        sx = {{backgroundColor:"#f9c571", borderRadius: "8px", borderStyle: "solid", borderWidth: "2px", borderColor: "#006547"}} // Use the class from your CSS file
      >
        <Box>
          <AddIcon className="addMaterialIcon" />
          <Typography variant="subtitle1" className="addMaterialText">
            Add Material
          </Typography>
        </Box>
      </ButtonBase>
    );
};

export default AddMaterialButton;
