import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

// Optional: Import Google Fonts in your CSS or HTML
// For example, in index.css add: 
// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');

// Create a theme instance with the font globally set
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif'
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log("theme", theme);
root.render(
  //<React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Helps normalize styling across browsers, including default fonts */}
      <App />
    </ThemeProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
