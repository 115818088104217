import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { getCsrfToken } from '../pages/App.js';
import { trackUserInteraction } from '../tracking.js';
import { trackEvent } from '../tracking.js';
import { useNavigate} from "react-router-dom";
import CreateStudyPlanModal from '../components/CreateStudyPlan.js';
import { differenceInDays, eachDayOfInterval, isSunday } from 'date-fns';

import NavBar from '../components/NavBar.js';
import PageNavigation from '../components/PageNavigation.js';
import DocumentViewer from '../components/DocumentViewer.js';
import SnippetBoard from '../components/SnippetBoard';
import AccountNavigation from '../components/AccountNavigation';
import { Modal, Box, Button, TextField } from '@mui/material';
import { useMediaQuery, Paper,CircularProgress, Typography } from '@mui/material';

import { processPdfPageAndExtractSnippets, extractSnippetsFromImage, setupCanvas, drawBlurredImage, drawAllSnippets } from '../utils/pdfUtils.js';
import { drawSnippets, convertSnippets, flattenDeep } from '../utils/snippetUtils.js';
import { fetchMaterialDetails, updateMaterialDetails } from '../services/materialService.js';

import './UserPage.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
//TODO: the last page is remembered from the database, but the last snippet isn't.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function UserPage({
  showNavBar = true,
  showAccountNavigation = true,
  sample = false
}) {
  //TODO: 
  //Consolidate Related States: Some state variables that relate to the PDF document (like currentPage, numPages, modifiedPdf) can be grouped into a single useState or useReducer to handle them more cohesively.
  //Optimize State Updates: Ensure state updates are batched when possible to avoid unnecessary re-renders.
  const { materialId } = useParams(); // Get the material ID from the URL
  const query = useQuery();
  const location = useLocation();
  const [SCALE, setSCALE] = useState(5);
  const [numPages, setNumPages] = useState(null); //Important
  const [currentPage, setCurrentPage] = useState(1); //Important
  const [tempModifiedPdf, setTempModifiedPdf] = useState(null)
  const [modifiedPdf, setModifiedPdf] = useState(null); 
  const pdfViewerRef = useRef(null);
  const [nCurrentSnippets, setNCurrentSnippets] = useState (1) //Important
  const [nTotalSnippets, setNTotalSnippets] = useState (0) //Important -
  const [snippets, setSnippets] = useState([]); //important - saves all the snippets (as urls to ) in a single list of urls
  const [todaysProgress, setTodaysProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const [materialDetails, setMaterialDetails] = useState(null); //Important - I think contains everything
  const [snippetsStatus, setSnippetsStatus] = useState([]); //Important - list of lists of integers
  const [snippetsOrder, setSnippetsOrder] = useState([])
  const [snippetsDates, setSnippetsDates] = useState([]); // kindaImportant
  const [snippetsQuestion, setSnippetsQuestion] = useState([]);
  const [revisionMode, setRevisionMode] = useState(false);
  const [targetNumPages, setTargetNumPages] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const [openModal, setOpenModal] = useState(true); // State to control modal visibility
  const [examDate, setExamDate] = useState('');
  const [studyDaysPerWeek, setStudyDaysPerWeek] = useState(2);
  const [todaysSteps, setTodaysSteps] = useState(0);
  const [todaysTarget, setTodaysTarget] = useState(0);
  const [studyDaysLeft, setStudyDaysLeft] = useState(null);
  

  //Helper state is needed to make loading of the new document sequential, if the temporaryModifiedPdf is updated.
   //If it is updated, hook 2 sets modifiedPdf to tempModifiedPdf and returns(!), then Document is rerendered, then onDocumentLoadSuccess is triggered,
    // then helperState is updated, then hook 2 and 4 are triggered again, and their actual logic is executed.
  const [helperState, setHelperState] = useState(0); 

  useEffect(() => {
    // This effect runs whenever the location (specifically search part) changes.
    console.log('useEffect location got triggered!');
    const processed = parseInt(query.get('processed'), 10);
    const target = parseInt(query.get('total'), 10);
    setTargetNumPages(target); 
    setNumPages(processed);
    if (processed < target && !sample ) {
      setIsLoading(true);
      processEachPage(materialId, processed, target);
    }
    else if (processed< target){
      setIsLoading(true);
    }
    else{
      setIsLoading(false)
    }
}, [location.search]); // Dependency on search ensures effect runs when query params change

  const possibleScales = [0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4];

  const processEachPage = (materialId, currentPage, totalPages) => {
    const maxRetries = 3; // Maximum number of retries
    let retryCount = 0;
  
    const fetchWithRetry = () => {
      if (currentPage >= totalPages || currentPage >= 50) {
        console.log('All pages processed or max limit reached');
        return;
      }
  
      const formData = new FormData();
      formData.append('material_id', materialId);
      formData.append('page_num', currentPage);
  
      fetch(`${process.env.REACT_APP_API_URL}app/process-page-backend`, {
        method: 'POST',
        body: formData,
        headers: {
          //'Authorization': `Token ${token}`,
        },
        credentials: 'include',
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Update the URL with the new processed page count
        navigate(`/current-session/${materialId}?processed=${currentPage + 1}&total=${totalPages}`);
      })
      .catch(error => {
        console.error(error);
        if (retryCount < maxRetries) {
          retryCount++;
          console.log(`Retrying... Attempt ${retryCount} of ${maxRetries}`);
          fetchWithRetry(); // Retry the request
        } else {
          console.error('Max retries reached. Request failed.');
          alert("Connection error. Check your internet connection and refresh this page. Don't worry, your progress is saved!");
        }
      });
    };
  
    fetchWithRetry(); // Initial fetch call
  };


    // #0 : fetch data based on page url
    useEffect(() => {
      console.log('hook 0'); 
      if (materialId) {
        fetchMaterialDetails(materialId, setMaterialDetails, modifiedPdf, setModifiedPdf, setTempModifiedPdf, query, setSnippetsStatus, setSnippetsOrder, setNumPages, setSnippetsDates, setSnippetsQuestion, setCurrentPage, setNCurrentSnippets, isLoading, setExamDate, setStudyDaysPerWeek);
        calculateTodaysTarget();
      }
      reshuffleOrder();
    }, [materialId, numPages, examDate, studyDaysPerWeek]);
  

    function calculateStepsLeft(arr) {
      // Step 1: Filter out integers equal to 5
      const filteredArray = arr.filter(num => num !== 5);
      
      // Step 2: Calculate the length of the filtered array
      const lengthExcludingFives = filteredArray.length;
      
      // Step 3: Calculate the progress so far
      const progressSoFar = filteredArray.reduce((sum, num) => {
        if (num === 2) return sum + 1;
        if (num === 3) return sum + 2;
        if (num === 4) return sum + 3;
        return sum;
      }, 0);
      
      // Step 4: Calculate the steps left
      const stepsLeft = (lengthExcludingFives * 3) - progressSoFar;
      
      return stepsLeft;
    }

    const calculateTodaysTarget = () => {
      const stepsLeft = calculateStepsLeft(snippetsStatus.flat());
      
      // Calculate the number of days left
      const today = new Date();
      const examDateObj = new Date(examDate);
      const totalDaysLeft = differenceInDays(examDateObj, today);
      
      const studyDaysLeft = Math.floor(totalDaysLeft * (studyDaysPerWeek / 7));
      // Calculate the number of study days left based on StudyDaysPerWeek
      setStudyDaysLeft(studyDaysLeft);
      // Calculate the today's target
      const dailyTarget = Math.ceil(stepsLeft / studyDaysLeft);
      // Set the todaysTarget state
      setTodaysTarget(dailyTarget);
    }

    const reshuffleOrder = () => {
      setSnippetsOrder((prevOrder) => {
        const snippetsWithStatusFive = [];
        const otherSnippets = [];
    
        prevOrder.forEach((snippet, index) => {
          const [page, snippetIndex] = snippet;
          if (snippetsStatus[index] === 5) {
            snippetsWithStatusFive.push(snippet);
          } else {
            otherSnippets.push(snippet);
          }
        });
    
        return [...otherSnippets, ...snippetsWithStatusFive];
      });
    };

    
    // #2: re-render the middle layer canvas each time the page changes
    useEffect(() => {
      console.log('hook 2'); 
      if (modifiedPdf !== tempModifiedPdf) {
        setModifiedPdf(tempModifiedPdf);
        return;
      }
      if (snippets.length === 0) {
        console.log("Hmm, snippets.length is zero!");
        return;
      }
    
      const canvas = document.getElementById('middleCanvas');
      const pdfViewer = pdfViewerRef.current;
      if (!canvas || !pdfViewer) {
        console.log('no canvas or no pdfViewerRef.current');
        return;
      }
      const pdfPageElement = pdfViewer.querySelector('.react-pdf__Page__canvas');
      if (!pdfPageElement) {
        console.log('no pdfPageElement');
        return;
      }
    
      drawAllSnippets(canvas, pdfPageElement, snippets, possibleScales[SCALE], materialDetails, currentPage);
    }, [materialId, currentPage, snippets, SCALE, helperState]); //modifiedPdf and tempModifiedPdf

    //#4: I suppose create snippets when currentPage change
  useEffect(() => {
    console.log('hook 4'); 
    async function handlePdfProcessingAndSnippetExtraction() {
      if (modifiedPdf !== tempModifiedPdf) return;
      if (!materialDetails || !materialDetails.snippets[currentPage - 1]) {
        console.log("Insufficient data to process snippets.");
        return;
      }

      const pageBboxes = flattenDeep(convertSnippets(materialDetails.snippets, currentPage));
      setNTotalSnippets(pageBboxes.length);
      // Reset to show only the first bbox initially

      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(modifiedPdf);
        await new Promise(resolve => fileReader.onload = resolve);
        const pageImage = await processPdfPageAndExtractSnippets(new Uint8Array(fileReader.result), currentPage, 8);
        const dataUrls = await extractSnippetsFromImage(pageImage, pageBboxes, 8);
        setSnippets(dataUrls);
      } catch (error) {
        console.error("Error in processing PDF and extracting snippets:", error);
      }
    }


    handlePdfProcessingAndSnippetExtraction();
  }, [currentPage, helperState]);

    
//#1 : change the current snippet displayed
  useEffect(() => {
    console.log('hook 1'); 
    if (snippets.length === 0) {
      console.log("Hmm, snippets length is zero!");
      return;
    }

    const canvas = document.getElementById('overlayCanvas');
    const pdfViewer = pdfViewerRef.current;
    if (!canvas || !pdfViewer) {
      console.log('No canvas or no pdfViewerRef.current');
      return;
    }

    const pdfPageElement = pdfViewer.querySelector('.react-pdf__Page__canvas');
    if (!pdfPageElement) {
      console.log('No pdfPageElement');
      return;
    }

    const ctx = setupCanvas(canvas, pdfPageElement);
    const allBboxes = convertSnippets(materialDetails.snippets, currentPage);
    if (hide){
      const currentSnippets = allBboxes.slice(0, nCurrentSnippets-1);
    drawSnippets(ctx, snippets, currentSnippets, possibleScales[SCALE], nCurrentSnippets-1);
    }
    else{
    const currentSnippets = allBboxes.slice(0, nCurrentSnippets);
    drawSnippets(ctx, snippets, currentSnippets, possibleScales[SCALE], nCurrentSnippets);}
  }, [currentPage, nCurrentSnippets, snippets, SCALE, hide]);

    //#4.5 update progress bars
    useEffect(()=>{
      console.log('hook 4.5'); 
      if (snippetsStatus && currentPage){
      setTodaysProgress(100*todaysSteps/todaysTarget);
      setProgress(calculateProgress(snippetsStatus.flat()));
      }
    }, [snippetsStatus, currentPage]);

    const onDocumentLoadSuccess = () => { // Assuming you're still tracking the number of pages
      console.log('Document Load Success'); 
      setHelperState(prevState => prevState + 1);
      if(!sample){trackEvent('App', 'Document_loaded');}else{
        trackEvent('App', 'Sample_Document_loaded')
      }
    //trackUserInteraction('Document Load Success');
    };

    const goToPreviousPage = () => {
      if (currentPage > 1) {
        const newPage = currentPage - 1;
        setCurrentPage(newPage);
        setNCurrentSnippets(1);
        if(!sample){
        trackEvent('App', 'previous_page');}
          else{trackEvent('App', 'Sample_previous_page');}
        setNCurrentSnippets(1);
        updateMaterialDetails(materialDetails.materialId, { lastPage: newPage, lastSnippet: nCurrentSnippets });
      }
    };
    
    //TODO: maybe the nCurrentSnippets should be the remembered for each page? 
    const goToNextPage = () => {
      if (currentPage < numPages) { // Ensure numPages is defined and represents the total number of pages
        const newPage = currentPage + 1;
        setCurrentPage(newPage);
        setNCurrentSnippets(1);
        if(!sample){
        trackEvent('App', 'next_page');}
        else {trackEvent('App', 'Sample_next_page');}
        setNCurrentSnippets(1);
        updateMaterialDetails(materialDetails.materialId, { lastPage: newPage, lastSnippet: nCurrentSnippets });
      }
    };
    
    const moveBack = () => {
      setNCurrentSnippets(prev => {
        let newBboxCount = prev;
    
        if (revisionMode) {
          const statuses = snippetsStatus[currentPage - 1];
          const sortedIndices = sortIndicesByStatus(statuses);
    
          // Find the current index in the sorted list
          const currentIndex = sortedIndices.indexOf(prev - 1); // Adjust for zero-indexing
          const prevIndex = currentIndex - 1 >= 0 ? sortedIndices[currentIndex - 1] : null;
    
          // Adjust for zero-indexing when setting the new index
          // If prevIndex is null, it means we are at the first index, so do not update newBboxCount
          if (prevIndex !== null) {
            newBboxCount = prevIndex + 1;
          }
        } else {
          // Normal mode: Simply decrement to the previous snippet if not at the start
          newBboxCount = prev > 1 ? prev - 1 : prev;
        }
    
        // Update server details if there's a change
        if (newBboxCount !== prev) {
          updateMaterialDetails(materialDetails.materialId, { lastPage: currentPage, lastSnippet: newBboxCount });
        }
    
        return newBboxCount;
      });
    };
    
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleAccept = () => {
      // Handle the accept action (e.g., save the study plan)
      handleCloseModal();
    };

    function sortIndicesByStatus(statuses) {
      const indices = statuses
        .map((status, index) => ({ status, index }))
        .filter(item => item.status !== 5) // Exclude snippets marked as 'Not Relevant'
        .sort((a, b) => a.status - b.status)
        .map(item => item.index);
      return indices;
    }

    const moveNext = () => {
      setNCurrentSnippets(prev => {
        let newBboxCount = prev;
    
        if (revisionMode) {
          const statuses = snippetsStatus[currentPage - 1];
          const sortedIndices = sortIndicesByStatus(statuses);
    
          // Find the current index in the sorted list
          const currentIndex = sortedIndices.indexOf(prev - 1); // Adjust for zero-indexing
          const nextIndex = currentIndex + 1 < sortedIndices.length ? sortedIndices[currentIndex + 1] : null;
    
          // Adjust for zero-indexing when setting the new index
          // If nextIndex is null, it means we are at the last index, so do not update newBboxCount
          if (nextIndex !== null) {
            newBboxCount = nextIndex + 1;
          }
        } else {
          // Normal mode: Simply increment to the next snippet if not at the end
          newBboxCount = prev < nTotalSnippets ? prev + 1 : prev;
        }
    
        // Update server details if there's a change
        if (newBboxCount !== prev) {
          updateMaterialDetails(materialDetails.materialId, { lastPage: currentPage, lastSnippet: newBboxCount });
        }
    
        return newBboxCount;
      });
    };

    const moveSpecific = (page, snippet) => {
      if (page > 0 && page <= numPages && snippet > 0 && snippet <= snippetsStatus[page - 1].length) {
        setCurrentPage(page);
        setNCurrentSnippets(snippet);
        updateMaterialDetails(materialDetails.materialId, { lastPage: page, lastSnippet: snippet });
      } else {
        console.warn("Invalid page or snippet number");
      }
    };

  async function sendSnippetData(snippet_no, page_no, material_no, snippet_status) {
        const url = 'YOUR_BACKEND_ENDPOINT_URL'; // Replace with your actual endpoint URL
        const payload = {
          snippet_no: snippet_no,
          page_no: page_no,
          material_no: material_no,
          snippet_status: snippet_status,
        };
      
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add any other headers your API requires, such as authentication tokens.
            },
            body: JSON.stringify(payload),
          });
      
          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }
      
          const data = await response.json(); // Assuming the server responds with JSON
          // Handle success - you can also return data here if needed
        } catch (error) {
          console.error('Error sending data:', error);
          // Handle errors, such as by displaying a message to the user
        }
      }

  const handleKeyPress = (event) => {
    // Check if the pressed key is the one you're interested in
    // For example, let's say you want to listen for the "Enter" key
    if (event.key === 'Enter') {
      // Execute your specific action here
    }
    if (event.key ==="ArrowRight"){
    moveNext();}
    if (event.key ==="ArrowLeft"){
      moveBack();
    }
  };

  function calculateProgress(numbers) {
    // Calculate the sum of 1, 2, 3, and 4s in the array
    if (numbers && numbers.length !== 0){
    const sumOfNumbers = numbers.reduce((acc, curr) => curr < 5 ? acc + curr : acc, 0);
    
    // Count the number of 5s in the array
    const countOfFives = numbers.filter(n => n === 5).length;
    
    // Calculate the divider: length of the array minus the number of 5s
    const divider = numbers.length - countOfFives;
    
    // Check if the divider is zero to avoid division by zero
    if (divider === 0) {
        return 0;
    }
    // Calculate and return the result
    return sumOfNumbers / (3* divider)* 100;} else {return 0;}
}

  const updateSnippetStatus = (newStatus, shouldMove=true, page=currentPage, nSnippet=nCurrentSnippets) => {
    setSnippetsStatus(prevStatus => {
      // Only copy the outer array and the specific inner array that needs to be updated
      const updatedStatus = [...prevStatus];
      const pageToUpdate = [...updatedStatus[currentPage-1]];
      // Update the specific snippet's status
      pageToUpdate[nCurrentSnippets-1] = newStatus;
      // Update the page Progress for the progress bar 
      // Update the outer array with the modified inner array
      updatedStatus[currentPage-1] = pageToUpdate;
      // update the total Progress for the progress bar
      return updatedStatus;
    });
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    
    setSnippetsDates(prevStatus => {
      // Only copy the outer array and the specific inner array that needs to be updated
      const updatedStatus = [...prevStatus];
      const pageToUpdate = [...updatedStatus[currentPage-1]];
      // Update the specific snippet's status
      pageToUpdate[nCurrentSnippets-1] = formattedDate;
      // Update the page Progress for the progress bar 
      // Update the outer array with the modified inner array
      updatedStatus[currentPage-1] = pageToUpdate;
      // update the total Progress for the progress bar
      return updatedStatus;
    });


    const snippet_no = nCurrentSnippets;
    const page_no = currentPage; // Adjust if your numbering isn't 1-based
    const material_no = materialId; // Assuming this is directly the material ID
  
    const data = {
      snippet_no: snippet_no ,
      page_no: page_no,
      material_no: material_no,
      snippet_status: newStatus,
      last_learned_date: today,
    };
  
    fetch(`${process.env.REACT_APP_API_URL}app/update_snippet_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include CSRF token as needed, e.g., 'X-CSRFToken': 'your-csrf-token-here'
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then(data => {
      // You can add any additional actions to be taken after successfully updating the snippet status
    })
    .catch(error => {
      console.error('There was a problem with your fetch operation:', error);
    });
    
    if (shouldMove){
    moveNext();
    }
  };  

  const handleRevisionModeChange = (newMode) => {
    setRevisionMode(newMode);
    if(!sample){
    trackEvent('Switch', `Revision Mode: turned ${newMode ? 'on' : 'off'}`);}
    else{trackEvent('Switch', `Sample_Revision Mode: turned ${newMode ? 'on' : 'off'}`);}
    if (newMode) {
      // Revision Mode just turned on, set to the first snippet according to sorted status
      const sortedIndices = sortIndicesByStatus(snippetsStatus[currentPage - 1]);
      if (sortedIndices.length > 0) {
        // Sets the current snippet to the first in the sorted list (plus 1 due to zero-index adjustment)
        setNCurrentSnippets(sortedIndices[0] + 1);
      }
    } else {
      // Revision Mode turned off, reset to the first snippet on the page
      setNCurrentSnippets(1);
    }
  };
  
  //#5: handle key presses.
  useEffect(() => {
    // Add event listener for the keydown event when the component mounts
    window.addEventListener('keydown', handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [nCurrentSnippets, nTotalSnippets]); // Hook for handling key presses ->cha

  function decreaseScale() {setSCALE(previousScale => previousScale - 1);} ;
  function increaseScale() {setSCALE(previousScale => previousScale + 1);};
  
  const setAllToZero = () => {
    // Create a new array with the same structure as snippetsStatus but with all elements set to 0
    const newStatus = snippetsStatus.map(subarray => subarray.map(() => 0));

    // Update the state with the new array
    setSnippetsStatus(newStatus);
  };

  return (
    <div className="background">
      {showNavBar ? (<NavBar loggedIn={true} />) : (<></>)}
      <div className="h-box">
        {showAccountNavigation ? (<AccountNavigation />) : (<></>)}
        <div className="input_pdf_viewer">
          <div className="pdf-viewer" ref={pdfViewerRef}>
            {modifiedPdf && (
              <>
                <PageNavigation
                  currentPage={currentPage}
                  numPages={numPages}
                  goToPreviousPage={goToPreviousPage}
                  goToNextPage={goToNextPage}
                  decreaseScale={decreaseScale}
                  increaseScale={increaseScale}
                  todaysProgress={todaysProgress}
                  progress={progress}
                  possibleScales={possibleScales}
                  SCALE={SCALE}
                  isLoading={isLoading}
                  studyDaysLeft={studyDaysLeft}
                />
                <DocumentViewer
                  modifiedPdf={modifiedPdf}
                  currentPage={currentPage}
                  onLoadSuccess={onDocumentLoadSuccess}
                  possibleScales={possibleScales}
                  SCALE={SCALE}
                />
              </>
            )}
          </div>
        </div>
        {modifiedPdf && (
            <SnippetBoard
              snippetsStatus={snippetsStatus}
              snippetsQuestion={snippetsQuestion}
              updateStatus={updateSnippetStatus}
              lastLearnedDate={snippetsDates[currentPage - 1][nCurrentSnippets - 1]}
              nCurrentSnippets={nCurrentSnippets}
              currentPage={currentPage}
              moveNext={moveNext}
              moveBack={moveBack}
              moveSpecific={moveSpecific}
              snippetsOrder={snippetsOrder}
              setSnippetsOrder={setSnippetsOrder}
              onRevisionModeChange={handleRevisionModeChange}
              sample={sample}
              hide={hide}
              setHide={setHide}
              materialId={materialId}
              setTodaysSteps={setTodaysSteps}
            />
        )}
      </div>
      {!examDate && (
        <CreateStudyPlanModal 
          materialId={materialId}
          open={openModal} 
          handleClose={handleCloseModal}
          examDate={examDate}
          setExamDate={setExamDate}
          studyDaysPerWeek={studyDaysPerWeek}
          setStudyDaysPerWeek={setStudyDaysPerWeek}
        />
      )}

    </div>
  );
}
//snippetsStatus[currentPage][nCurrentSnippets]
export default UserPage;
