import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import {getCsrfToken} from '../pages/App.js';
import {trackLogin} from '../tracking.js';
import ReactGA from 'react-ga4';
import { loadGoogleAnalytics, loadMicrosoftClarity } from '../pages/App.js';

function LoginForm() {
  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize navigate
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const csrfToken = getCsrfToken();
    const credentials = { username: email, password }; // Adjust the object keys according to your backend expectations
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}accounts/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'X-CSRFToken': csrfToken
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem('token', data.token); // Storing the token
        localStorage.setItem('userId', data.user_id); // Storing the user ID
        ReactGA.initialize('G-5MRSDQ2VCN');
          //ReactGA.initialize('G-5MRSDQ2VCN');  IMPORTANT - some information wont be transmitted until logged in. Not sure which
        localStorage.setItem('cookieConsent', 'true');
        loadGoogleAnalytics();
        loadMicrosoftClarity();
        alert("Login successful");
        trackLogin();
        navigate("/my-materials"); // Navigate after login
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error(error);
      alert("Login failed");
      // Handle the login failure
    }
  };

  
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <TextField
        label="Email" // Change to Email if you're using email to log in
        variant="filled"
        type="email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)}
        required
        fullWidth
        sx={{
          borderRadius: "10px",
          '& .MuiInputBase-input': { },
          '& .MuiOutlinedInput-notchedOutline': { },
        }}
      />
      <TextField
        label="Password"
        variant="filled"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        fullWidth
        sx={{
          '& .MuiInputBase-input': {  },
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
        }}
      />
      <Button type="submit" variant="contained" sx={{ background: 'linear-gradient(45deg, #006547, #004065)', boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)', // Multiple shadows for even effect
        '&:hover': {
          background: 'linear-gradient(45deg, #006547, #004065)', // Maintain the gradient background on hover
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)', // Glowing effect with multiple shadows
        }, mt: 2 }}>
        Login
      </Button>
    </Box>
  );
}

export default LoginForm;
