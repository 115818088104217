import React, { useState, useEffect, useRef } from 'react';
import './Slideshow.css';
import myVideo4 from '../images_slides/Demo_full_notes.mp4';


const Slideshow = () => {
    const [videoLoaded, setVideoLoaded] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        // Wait until the main content is loaded
        window.addEventListener('load', () => {
            setVideoLoaded(true);
        });
    }, []);

    return (
        <video
            ref={videoRef}
            className="slideshow"
            loading="lazy"
            autoPlay
            loop
            muted
            playsInline
            style={{marginLeft: '50px', display: videoLoaded ? 'block' : 'none' }} // Hide video until it's loaded
            onLoadedData={() => videoRef.current.play()}
        >
            {videoLoaded && <source src={myVideo4} type="video/mp4" />}
            Sorry, your browser does not support embedded videos.
        </video>
    );
};

export default Slideshow;