import React from 'react';
import ProgressBar from '../components/ProgressBar';
import { CircularProgress, Typography } from '@mui/material';
import '../pages/UserPage.css';

function PageNavigation({ currentPage, numPages, goToPreviousPage, goToNextPage, decreaseScale, increaseScale, todaysProgress, progress, possibleScales, SCALE, isLoading, studyDaysLeft }) {
  return (
    <div className="page-navigation">
      <div className="arrows-and-page">
        <button className='nav-button' onClick={goToPreviousPage}>&lt;</button>
        <span style={{ color: "white", marginLeft: "10px", marginRight: "10px" }}>Page {currentPage} of {numPages}</span>
        <button className="nav-button" onClick={goToNextPage}>&gt;</button>
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
            <CircularProgress size={20} sx={{ color: 'white' }} />
            <Typography variant="body2" sx={{maxWidth:'400px', color: 'white', marginLeft: '5px' }}>
              {numPages >= 50 ? 'Reached the maximum number of pages' : 'Loading rest of the pages.\n Your daily target might change until all pages are loaded'}
            </Typography>
          </div>
        )}
      </div>
      <div className="arrows-and-page">
        <button className='nav-button' onClick={decreaseScale}>-</button>
        <span style={{ color: "white", marginLeft: "10px", marginRight: "10px" }}>Zoom: {possibleScales[SCALE]}</span>
        <button className="nav-button" onClick={increaseScale}>+</button>
      </div>
      <div className="ProgressBars">
        {!isNaN(todaysProgress)  && (
          <ProgressBar progress={todaysProgress} color="#f9c571" title="Daily Progress"/>
        )}
        <ProgressBar progress={progress} color="#f9c571" title="Material Progress"/>
        {!isNaN(studyDaysLeft) && (
          <Typography variant="body2" sx={{ color: 'white', fontSize: '16px' }}>
            Study Days Left: {studyDaysLeft}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default PageNavigation;
