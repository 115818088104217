import React, {useState} from "react";
import { Box, Button, Divider, Typography, Drawer , useMediaQuery} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextField from '@mui/material/TextField';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import './AccountNavigation.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { trackPageView } from "../tracking";

const AccountNavigation = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to handle drawer visibility
    const navigate = useNavigate(); // Hook for navigation
    const handleCurrentSessionClick = () => {
        navigate('/current-session');
        trackPageView('current_session');
      };
    
      const handleMyMaterialsClick = () => {
        navigate('/my-materials');
        trackPageView('my_materials');
      };
    
      const handleMyAccountClick = () =>{
        navigate('/my-account');
        trackPageView('my_account');
      };

      const [feedback, setFeedback] = useState(''); // State to hold the feedback text

      const isMobile = useMediaQuery('(max-width: 997px)');

      const sendFeedback = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}app/feedback`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken // Uncomment and use if CSRF protection is needed
            },
            body: JSON.stringify({ feedback }),
          });

          const data = await response.json();
          if (response.status === 200 || response.status === 201) {
            alert("Feedback sent successfully");
            setFeedback('');
            // You can clear the feedback form or perform other actions
          } else {
            throw new Error('Failed to send feedback');
          }
        } catch (error) {
          console.error(error);
          alert("Failed to send feedback");
        }
      };

      const toggleDrawer = (open) => () => {
        setIsDrawerOpen(open);
    };

    const navigationContent = (
      <>
          <Typography variant="h3" sx={{ color: "var(--color4)", fontSize: '1.5rem', marginBottom: '16px' }}>
              My Learning Space 🎓
          </Typography>
          <Divider sx={{ borderColor: 'var(--color4)', marginBottom: '16px' }} />

          <Button
              variant="outlined"
              startIcon={<MenuBookIcon />}
              sx={{ color: 'var(--color4)', backgroundColor: 'rgba(255,255,255,0.5)','&:hover': {
                boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)'}, boxShadow: '0 0 10px rgba(0, 64, 101, 0.3)' }}
              onClick={handleMyMaterialsClick}
          >
              My Materials
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="outlined" startIcon={<PersonIcon />} onClick={handleMyAccountClick} sx={{ color: 'var(--color4)', backgroundColor: 'rgba(255,255,255,0.5)','&:hover': {
                      boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)'}, boxShadow: '0 0 10px rgba(0, 64, 101, 0.3)' }}>
              My Account
          </Button>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", gap: '10px', marginTop: '20px' }}>
          <Typography className="clarification" sx={{ color: 'var(--color4)', fontSize: '0.9rem'}}>
            One thing that should be improved is...
          </Typography>
              <TextField
                  multiline
                  rows={3}
                  variant="standard"
                  placeholder="Your feedback"
                  value={feedback} // Control component
                  onChange={(e) => setFeedback(e.target.value)} // Update state on change
                  sx={{
                      width: '100%',
                      backgroundColor: 'white',
                      borderRadius: '10px',
                      '& .MuiInputBase-root': {
                          border: '1px solid green',
                          borderRadius: '10px',
                      },
                      '& .MuiInput-underline:before': {
                          borderBottom: 'none',
                      },
                      '& .MuiInput-underline:after': {
                          borderBottom: 'none',
                      },
                      '& .Mui-focused .MuiInputBase-root': {
                          borderColor: 'green',
                      },
                  }}
              />
              <Button
                  type="submit"
                  variant="contained"
                  onClick={sendFeedback} // Call sendFeedback on click
                  sx={{
                      background: 'linear-gradient(45deg, #006547, #004065)',
                      boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)',
                      '&:hover': { backgroundColor: '#E04A2F' ,
                      boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)'}
                  }}
              >
                  Send
              </Button>
          </div>
      </>
  );

     return (
        <Box>
            {isMobile ? (
                <>
                    <Button onClick={toggleDrawer(true)} className="toggle-button" sx= {{backgroundColor: "#f9c571", color: "white", borderRadius: "0 10px 10px 0"}}>
                        Menu
                    </Button>
                    <Drawer
                        anchor="left"
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                        
                    >
                        <Box
                            className = "drawer"
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            {navigationContent}
                        </Box>
                    </Drawer>
                </>
            ) : (
                <Box className="account-navigation">
                    {navigationContent}
                </Box>
            )}
        </Box>
    );
    }
    
    export default AccountNavigation;