import React, {useState} from "react";
import './UserPage.css'; // TODO: refactor the .css references -> right now it takes it from the UserPage.css  
import AccountNavigation from "../components/AccountNavigation";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Typography } from '@mui/material';
import './MyMaterials.css'
import {useNavigate} from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import Logo from "../components/output_logo3.png"
import NavBar from "../components/NavBar";

const MyAccount = () => {
  const navigate = useNavigate();
  const [openProfile, setOpenProfile] = useState(false); // For profile basics dialog
  const [userData, setUserData] = useState(null);

  const handleClickOpenProfile = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}app/user_data`, {  // Adjust this endpoint as necessary
        method: 'GET',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`, // Adjust according to your auth method
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("user data", data);
        setUserData(data); // Store the user data in state
        setOpenProfile(true); // Open the profile dialog after fetching data
      } else {
        console.error('Failed to fetch user data:', response.statusText);
        // Handle errors or unauthorized access
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };


  const goToProfileBasics = ()=>{};
  const deleteAccount = () => {
    handleClickOpen();
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const logout = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}accounts/logout`, {
          method: 'POST',
          headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
            // Clear the session from local storage or cookies
            // Redirect or update UI accordingly
            console.log('Logout successful');
            localStorage.removeItem('token');
            navigate('/');
        } else {
            console.error('Logout failed:', response.statusText);
        }
    } catch (error) {
        console.error('Error during logout:', error);
    }
};
    const materials = [
        { title: 'Material 1', progress: 20 },
        { title: 'Material 2', progress: 50 },
        // Add more materials as needed
      ];
    return(
      <div className="background">
        <Dialog open={openProfile} onClose={handleCloseProfile} maxWidth="sm" fullWidth>
        <DialogTitle>
          Profile Basics
          <IconButton aria-label="close" onClick={handleCloseProfile} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
  {userData ? (<div>
    <Box sx={{ padding: 2 }}>
      <Typography variant="body1" gutterBottom><strong>Email:</strong> {userData.email}</Typography>
      <Typography variant="body1" gutterBottom><strong>Date of Birth:</strong> {userData.date_of_birth || 'Not provided'}</Typography>
      <Typography variant="body1" gutterBottom><strong>Education Level:</strong> {userData.education_level}</Typography>
      {userData.degree && <Typography variant="body1" gutterBottom><strong>Degree:</strong> {userData.degree}</Typography>}
      {userData.university && <Typography variant="body1" gutterBottom><strong>University:</strong> {userData.university}</Typography>}
      {userData.year && <Typography variant="body1" gutterBottom><strong>Year/Class:</strong> {userData.year}</Typography>}
      {userData.country && <Typography variant="body1" gutterBottom><strong>Country:</strong> {userData.country}</Typography>}
    </Box>
    <p>To modify your data, send us an email from your account's email address to learnnavigator@gmail.com</p>
    </div> 
  ) : (
    <DialogContentText>Loading user data...</DialogContentText>
  )}
</DialogContent>

        <Button onClick={handleCloseProfile} color="rgba(255, 255, 255, 0.5)">
          Close
        </Button>
      </Dialog>
        <Dialog open={open} onClose={handleClose}>
  <DialogTitle>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
    <DialogContentText>
      To delete your account, send us an email from your account's email address to: learnnavigator@gmail.com.
    </DialogContentText>
  </DialogContent>
  <Button onClick={handleClose} sx={{ color: 'rgba(255, 255, 255, 0.5)'}}>
    Close
  </Button>
</Dialog>
<NavBar loggedIn={true}/>
      <div className="h-box" style = {{marginTop: '64px',}}>
        <AccountNavigation />
        <Box sx ={{display:'flex', alignItems: 'center', justifyContent: 'Center', width: '100%'}}>
          <Box className="orange-bar">
            {/* Content for the orange bar */}
          </Box >
          <div className="v-box">
          <Button variant="contained" onClick={logout} sx={{ margin: 1, width: '200px', background: 'linear-gradient(45deg, #006547, #004065)'}}>
            Logout
          </Button>
          {/*<Button variant="contained" onClick={handleClickOpenProfile} sx={{ margin: 1, width: '200px', backgroundColor: '#006547' }}>
        Profile Basics
    </Button>*/}
          <Button variant="contained"  onClick={deleteAccount} sx={{ margin: 1, color:"var(--color4)", width: '200px', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            Delete Account
          </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};


export default MyAccount;