class SnippetExtractor {
    giveSnippets(pdfPage, bboxes, image_scale) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const snippetDetails = []; // Will store snippet data URLs along with dimensions

            img.onload = () => {
                bboxes.forEach(bbox => {
                    const [x0, y0, x1, y1] = bbox;
                    const width = (x1 - x0)*image_scale;
                    const height = (y1 - y0)*image_scale;

                    // Create a canvas for each snippet
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');

                    // Draw the specified portion of the image onto the canvas
                    ctx.drawImage(img, x0*image_scale, y0*image_scale, width, height, 0, 0, width, height);

                    // Convert the canvas to a data URL for the snippet
                    const snippetDataUrl = canvas.toDataURL('image/png');
                    // Push both the data URL and dimensions into the array
                    snippetDetails.push({dataUrl: snippetDataUrl, width, height});
                });

                resolve(snippetDetails);
            };

            img.onerror = (e) => reject(e);

            // Set the source of the image to the PDF page data URL
            img.src = pdfPage;
        });
    }
}

export default SnippetExtractor