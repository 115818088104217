import React from "react";
import './UserPage.css'; // TODO: refactor the .css references -> right now it takes it from the UserPage.css  
import AccountNavigation from "../components/AccountNavigation";
import { Box, Grid, CircularProgress, Typography } from '@mui/material';
import MaterialButton from '../components/MaterialButton'; // This component needs to be created
import './MyMaterials.css'
import AddMaterialButton from "../components/AddMaterialButton";
import {useState, useEffect,  useRef } from "react"
import { useNavigate} from "react-router-dom";
import Logo from "../components/output_logo3.png"
import { trackEvent } from "../tracking.js";
import NavBar from "../components/NavBar";
import { useMediaQuery, Paper } from '@mui/material';

const MyMaterials = () => {
  const [numPage, setNumPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const token = localStorage.getItem('token');
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:500px)');

  const handleFileChange = (event) => {
    trackEvent('Document', 'started_uploading_document');
    const file = event.target.files[0];
    if (file) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('pdfFile', file);
        formData.append('fileName', file.name);

        fetch(`${process.env.REACT_APP_API_URL}app/modify-pdf`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Token ${token}`,
            },
            credentials: 'include',
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data.message);
            console.log('data', data);
            const nTotal = data.docLen;
            setTotalPage(nTotal);
            setNumPage(1);
            processEachPage(data.materialId, 0, nTotal);
        })
        .catch(error => {
            console.error(error);
        });
    }
};

//TODO: add a trackEvent if all pages (or 50 pages) are processed.
const processEachPage = (materialId, currentPage, totalPages) => {
  // Check if the current page exceeds 50 or if it's the last page to process
  if (currentPage >= totalPages || currentPage >= 50) {
    setIsLoading(false); // Stop loading
    console.log('All pages processed or max limit reached');
    return;
  }

  const formData = new FormData();
  formData.append('material_id', materialId);
  formData.append('page_num', currentPage);

  fetch(`${process.env.REACT_APP_API_URL}app/process-page-backend`, {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': `Token ${token}`,
    },
    credentials: 'include',
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    console.log(data.message);
    setNumPage(currentPage + 1); // navigate after the first page is processed
      navigate(`/current-session/${materialId}?processed=${currentPage + 1}&total=${totalPages}`);
  })
  .catch(error => {
    console.error(error);
  });
};

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_URL}app/materials`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${token}`, 
      'Content-Type': 'application/json'
    }, 
    credentials: 'include'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setMaterials(data); // Assuming the API returns an array of materials
      })
      .catch(error => console.error('Error fetching materials:', error));
  }, []);

  const addMaterial =()=> {fileInputRef.current.click();};

  const [materials, setMaterials] = useState([]);

  const deleteMaterial = (id) => {
      // Ask user to confirm deletion
      const isConfirmed = window.confirm("Are you sure you want to delete this material?");
      
      // Proceed with deletion if the user confirmed
      if (isConfirmed) {
        fetch(`${process.env.REACT_APP_API_URL}app/materials/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`}, 
            credentials: 'include'
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Handle successful deletion here
          // For example, remove the deleted material from the state to update the UI
          setMaterials(prevMaterials => prevMaterials.filter(material => material.id !== id));
          trackEvent('Document', 'delete_document');
        })
        .catch(error => console.error('Error deleting material:', error));
      } else {
        // If the user clicks "Cancel", do nothing
        console.log('Deletion cancelled.');
      }
    };


    return(
      <div className="background">
<NavBar loggedIn={true} />
          {/* Container for displaying snippets */}
          <div className = "h-box" style = {{marginTop: '64px',}}>
            <AccountNavigation /> 
            <Box className="my-materials" sx={{ display: 'flex', flexDirection: 'column' }}>
    {isLoading ? (
      <div style={{
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'center', // Center vertically in the flex container
          alignItems: 'center', // Center horizontally in the flex container
          height: '100vh' // Use a full viewport height to ensure there's enough space to center
        }}>
        <CircularProgress />
        <Typography variant="subtitle1" sx={{ color: 'black', textAlign: 'center' }}>
    Processing page {numPage} of {Math.min(totalPage, 50)}. <br/>
    You can view your document in just a moment.
</Typography>
      </div>
    ) : (<div className = 'help'>
      <Grid container spacing={2} sx={{ padding: '20px', flexGrow:1 }}>
        {materials.map((material, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <MaterialButton
              id={material.id}
              title={material.title}
              progress={material.progress}
              onClick={() => navigate(`/current-session/${material.id}`)}
              onDelete={deleteMaterial}
              
            />
          </Grid>
        ))}
        {/* Add Material Button */}
        <Grid item xs={12} sm={6} md={3}>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            style={{ display: 'none' }} // Hide the file input
            ref={fileInputRef} // Attach the ref
          />
          <AddMaterialButton onClick={addMaterial}/>
        </Grid>
      </Grid>
      <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }}
>
  <Typography
    variant="body2"
    sx={{ marginTop: 'auto', textAlign: 'left', marginBottom: '40px', color: 'var(--color4)', fontSize: '0.9rem', maxWidth: '600px' }}
  >
    What study materials to upload?
    <br />
    ✔️ lecture slides, typed and handwritten notes in PDF
    <br />
    ❌ scripts, exercise sheets, documents with long text, pictures
  </Typography>
</Box>
    </div>
    )}
    
</Box>
          </div>
        </div>);
}


export default MyMaterials;