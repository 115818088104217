import React from 'react';
import './ProgressBar.css'; // Import the CSS for styling
import { Typography } from '@mui/material';

const ProgressBar = ({ progress, color, title }) => {
  // Ensure progress does not exceed the bounds
  const validProgress = Math.min(100, Math.max(0, progress));

  return (
    <div className="progress-bar-wrapper">
      {title && <><span className="title">{title}</span>
      <div className="info-icon">
      ℹ️
      <div className="info-box">
        <Typography sx={{ color: 'var(--color4)', width: '100%', fontSize: '14px' }}>
Each concept can have a status: 0 (Unseen), 1 (Difficult), 2 (Understood), 3 (Answered), or 'Not Relevant'. The progress is calculated by summing the statuses of all concepts of the material and dividing by three times the number of relevant concepts (those not marked as 'Not Relevant').
        </Typography>
      </div>
    </div></>}
      <div className="progress-bar-container">
        <div
          className="progress-bar-fill"
          style={{
            width: `${validProgress}%`,
            backgroundColor: color,
            transition: 'width 0.5s ease-in-out', // Smooth transition for width change
          }}
        >
          <span className="percentage">{`${Math.round(validProgress)}%`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
