import React from 'react';
import { Box, Typography, LinearProgress, ButtonBase } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description'; // PDF/File Icon
import CloseIcon from '@mui/icons-material/Close'; // Import the close (X) icon

const MaterialButton = ({ id, title, progress, onClick, onDelete }) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        backgroundColor: '#fbd79c', // Transparent background
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          right: '-2px',
          bottom: '-2px',
          borderRadius: 'inherit',
          padding: '2px',
          background: 'linear-gradient(45deg, #006547, #004065)',
          WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
          zIndex: -1,
        },
        boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)',
        width: '130px',
        minHeight: '130px',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease',
        '&:hover': {
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)',
          transform: 'translateY(-5px)',
        },
      }}
    >
      <CloseIcon
        onClick={(e) => {
          e.stopPropagation(); // Prevent the ButtonBase onClick from firing
          onDelete(id); // Call the onDelete function passed as a prop
        }}
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          background: 'linear-gradient(45deg, #006547, #004065)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          cursor: 'pointer',
        }}
      />
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DescriptionIcon
          sx={{
            fontSize: '60px',
            background: 'linear-gradient(45deg, #006547, #004065)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            paddingLeft: '5px',
            paddingRight: '5px',
            background: 'linear-gradient(45deg, #006547, #004065)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {title}
        </Typography>      
      </Box>
    </ButtonBase>
  );
};

export default MaterialButton;
