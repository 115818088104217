import ReactGA from 'react-ga4';
 // Initialize GA

 export const getUserId = () => {
  return localStorage.getItem('userId');
};


// Track page views, including the user ID if available
export const trackPageView = (page) => {
  const userId = getUserId();
  const pageParams = { page };

  if (userId) {
    if (userId !=1){
    pageParams.userId = userId;}
  }

  //ReactGA.set(pageParams);
  //ReactGA.pageview(page);
};

// Track events, including the user ID if available
export const trackEvent = (category, action, label = null) => {
  const userId = getUserId();
  const eventParams = { category, action };

  if (label) {
    if (userId !=1){
    eventParams.label = label;}
  }

  if (userId) {
    if (userId !=1){
    eventParams.userId = userId;}
  }

  if (userId !=1){
  ReactGA.event(eventParams);}
};

export const trackRegistration = () => {
  const userId = getUserId();
  if (userId !=1){
  trackEvent('User', 'Registered');}
};

export const trackLogin = () => {
  const userId = getUserId();
  console.log('userId', userId);
  if (userId !=1){
  trackEvent('User', 'Logged In');}
};