import React, { useState } from 'react';
import './Faq.css';

const FAQ = () => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const faqItems = [
    {
      question: 'What kind of materials can I use it with?',
      answer: 'LearnNavigator.io works best with handwritten and digital notes ✍️, as well as lecture slides 📊. These materials have a high density of information, allowing you to learn precisely what you need. LearnNavigator.io does not work (yet) with scripts 📜, exercise sheets 📄, documents with long texts 📖, or single images 📷.'
    },
    /*{
      question: 'What if my document has images?',
      answer: 'Even better! Then each image is treated as one concept 🖼️. You can learn it or mark it as "Not Relevant" as you would any other concept!'
    },*/
    {
      question: 'Can I use it for my slides?',
      answer: 'LearnNavigator.io works with any PDF document that contains text, including your slides. Try it and elevate your slides game!'
    },
    {
      question: 'Can I use LearnNavigator.io in my degree?',
      answer: 'LearnNavigator.io is suitable for any course which can be learned from notes. Students of Engineering ⚙️, Natural Sciences 🔬, Business 💼, Medicine ⚕️, and Social Sciences 🌏 successfully use LearnNavigator.io to improve their grades.'
    },
    {
      question: 'How is LearnNavigator.io different than flashcards?',
      answer: 'Flashcards often isolate pieces of information, making it challenging to grasp complex concepts that require a broader context 👎. Moreover, they do not ensure mastery of the entire material unless you meticulously create each flashcard yourself.'
    },
    {
      question: 'Can I use it when learning with friends?',
      answer: 'Definitely! LearnNavigator.io is ideal for group study sessions. It lets you quiz each other, making learning with friends both effective and enjoyable. LearnNavigator.io can even be used as a date idea 👩‍❤️‍💋‍👨!'
    }
  ];

  return (
    <div className="w-layout-blockcontainer container-3 w-container">
      <div className="container-3-internal">
        <h1 className="heading-2"><span>FAQ 🧐</span></h1>
        {faqItems.map((item, index) => (
          <div className="faq-item" key={index}>
            <div className={`faq-question ${expanded[index] ? 'expanded' : ''}`} onClick={() => toggleExpand(index)}>
              {item.question}
              <span className={`triangle ${expanded[index] ? 'up' : 'down'}`}></span>
            </div>
            <div className={`faq-answer ${expanded[index] ? 'expanded' : ''}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
