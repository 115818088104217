import React from 'react';
import { Box, Typography, Button, Paper, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/Login';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import './LoginPage.css'

const MobileWarning = () => (
  <Paper elevation={3} sx={{ margin: '10px', padding: '20px', backgroundColor: 'rgba(255, 255, 255, 0.5)', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h6" gutterBottom style={{ color: '#004065', textAlign: 'center' }}>
      Please note: This application is currently optimized for desktop use. For the best experience, we recommend accessing this page on a desktop computer.
    </Typography>
  </Paper>
);

const LoginPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:767px)'); // using 600px as the breakpoint for a mobile device

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <NavBar />
      {/* Display warning if on a mobile device */}
      
    
      <Box className= "gradient-background"
      sx={{
        paddingTop: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '80px',
        flex: '1',
        backgroundAttachment: "fixed",
        boxShadow:'0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)'
      }}>
        
        <Paper sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', marginTop: '80px', marginRight: '5px', marginLeft: '5px', padding: '20px', display: 'flex', flexDirection: 'column', borderRadius: "20px", gap: '20px', alignItems: 'center', boxShadow: "0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)"}}>
          <Typography variant="h4" gutterBottom color="#004065">Login to LearnNavigator.io 🤘</Typography>
          <LoginForm />
          <Button variant="text" color="secondary" onClick={() => navigate('/forgot-password')}>Forgot Password?</Button>
        </Paper>
      </Box>
      <Footer className="footer-sticky"/>
    </div>
  );
};

export default LoginPage;

/*{/{isMobile && <MobileWarning />}*/