import React from 'react';
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import Register from '../components/Register';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import './LoginPage.css'
import './Register.css'

const MobileWarning = () => (
  <Paper elevation={3} sx={{ margin: '10px', padding: '20px', background: 'linear-gradient(45deg, #006547, #004065)', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h6" gutterBottom style={{ color: 'white', textAlign: 'center' }}>
      Please note: This application is currently optimized for desktop use. For the best experience, we recommend accessing this page on a desktop computer.
    </Typography>
  </Paper>
);

const RegisterPage = () => {
  const isMobile = useMediaQuery('(max-width:767px)');  // Using 600px as the breakpoint for mobile devices

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <NavBar />
      
      {/* Display warning if on a mobile device */}
      

      <Box className="gradient-background"
      sx={{
        paddingTop: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1',
        backgroundAttachment: "fixed", 
      }}>
        
        <Paper className="paper" sx={{maxWidth: '100vw', backgroundColor: 'rgba(255, 255, 255, 0.5)',marginRight: '5px', marginLeft: '5px', marginTop: '80px', marginBottom: '80px',padding: '20px', display: 'flex', flexDirection: 'column', borderRadius: "20px", gap: '20px', alignItems: 'center', boxShadow: "0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)"}}>
          <Typography variant="h4" gutterBottom color="#004065" sx = {{ marginBottom: '0px'}}>Register for LearnNavigator.io 🤸</Typography>
          <Typography variant="h6" color="#004065" sx = {{marginTop: '0px', marginBottom: '0px'}}>Join us for free!</Typography>
          <Register />
        </Paper>
      </Box>
      <Footer />
    </div>
  );
};

export default RegisterPage;
/*{isMobile && <MobileWarning />}*/