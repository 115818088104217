import React, {useState, useEffect, useRef} from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Slideshow from '../components/Slideshow';
import html2canvas from 'html2canvas';
import FAQ from '../components/Faq';
import Lottie from 'lottie-react';
import animationData from './Animation - 1718206292040.json'; 
import animationData2 from './Animation - 1718207433880.json';

import Microsoft from './images/Microsoft-for-Startups.jpg'
import UnternehmerTUM from './images/UnternemerTUM-logo-no_background.jpg'
import CDTM from './images/2017_CDTM_Logo_black_incl-protected-area.png'

import './css/normalize.css';
import './css/webflow.css';
import  './css/learnnavigator.webflow.css';
import './css/custom-table.css';
import notes from './images/Notes-example.png';
import slides from './images/Screenshot_slides.png';
import me from './images/me_coffee3.jpg';
import editedGirlImg from './images/Edited_girl-removebg-preview copy_final.png';
import { ReactComponent as BookOpenSolid } from './images/book-open-solid.svg';
import {ReactComponent as LightbulbSolid } from './images/lightbulb-solid.svg';
import {ReactComponent as RevisionIcon } from './images/revision_icon.svg';
import {ReactComponent as RotateRightSolid} from './images/rotate-right-solid.svg';
import {ReactComponent as Upload} from './images/upload-solid.svg'
import {ReactComponent as Question} from './images/question-solid.svg'
import {ReactComponent as Binoculars} from './images/binoculars-solid.svg'
import boyEditedImg from './images/boy_edited-removebg-preview - Copy.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LandingSample from '../components/LandingSample';
import { useLocation, useParams } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LandingPage = () => {
  const [feedback, setFeedback] = useState(''); // State to hold the feedback text
  const navigate = useNavigate();
  const query = useQuery();
  const { materialId } = useParams();
  const location = useLocation();
  const [numPages, setNumPages] = useState(0);

    const captureRef = useRef(null);
  
    const handleCapture = () => {
      html2canvas(captureRef.current).then(canvas => {
        // Convert the canvas to a data URL
        const imgData = canvas.toDataURL('image/png');
        // Create a link element
        const link = document.createElement('a');
        // Set the download attribute with a filename
        link.download = 'captured-element.png';
        // Set the href to the data URL
        link.href = imgData;
        // Trigger a click event on the link to download the image
        link.click();
      });
    };

  useEffect(()=>{
    //handleCapture();
  }, [])

  useEffect(() => {
    // This effect runs whenever the location (specifically search part) changes.
    const processed = query.get('processed');
    setNumPages(processed);
  }, [location.search]); // Dependency on search ensures effect runs when query params change
  

  const sendFeedback = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}app/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'X-CSRFToken': csrfToken // Uncomment and use if CSRF protection is needed
        },
        body: JSON.stringify({ feedback }),
      });

      const data = await response.json();
      if (response.status === 200 || response.status === 201) {
        alert("Feedback sent successfully");
        // You can clear the feedback form or perform other actions
      } else {
        throw new Error('Failed to s feedback');
      }
    } catch (error) {
      console.error(error);
      alert("Failed to send feedback");
    }
  };



  const goToRegister = () => {
    navigate('/register');
  };

  //const imagesForSlideshow = [
   // image1, image2, image3];

  return (
    <div className='gradient-background'>
    <div className="body-2">
      <NavBar/>
    <div className="w-layout-vflex flex-block">
      <div className="div-block">
        <div className="div-block-5">
          <h1 className="heading"><span className="text-span-2"><span className= 'text-gradient'>Ace Your Exams Like a Pro</span>📝<span className= 'text-gradient'>:<br></br>Guilt- and Stress-free</span></span></h1>
          <h3 className="heading-3"><span className="text-span-3 text-gradient">
    Review your <em>notes</em> like you would <em>flashcards</em> with a novel, AI-powered study method.
  </span></h3>
  <Button
      type="submit"
      onClick={goToRegister}
      variant="contained"
      sx={{
        background: 'linear-gradient(45deg, #006547, #004065)',
        boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)', // Multiple shadows for even effect
        '&:hover': {
          background: 'linear-gradient(45deg, #006547, #004065)', // Maintain the gradient background on hover
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)', // Glowing effect with multiple shadows
        },
        marginTop: '40px',
        color: 'white', // Ensure text color is white
      }}
    >
      Register now for free!
    </Button>
          {/* Removed "Sign In" button for simplicity */}
        </div>
      </div>
      <div className="w-layout-hflex flex-block-2">
      <Lottie animationData={animationData} className="image-16" loop={true} />
        <div className="div-block-2 colorclass">
        <div className="div-block-2-internal">
          <h2 className="heading-10"><span className="text-span"><span className="text-gradient">How it works </span>🤔</span></h2>
          <ul role="list" className="list">
          <li className="list-item">
  <div className="list-flex-container font-gradient">
    <span className="how-icon">📤</span>
    <span className="text-gradient how-item">
      Upload your study notes in PDF format and let LearnNavigator.io divide it into small, digestible concepts.
      <span className="how-item-2 font-gradient"><br></br>Tip: extract PDF from your notetaking app, use <a href="https://tools.pdf24.org/en/ocr-pdf">https://tools.pdf24.org/en/ocr-pdf</a> or Microsoft Lens!</span>
    </span>

    
  </div>
  
</li>
        <li className="list-item-3">
          <div className="list-flex-container font-gradient">
            <span className="how-icon">📅</span>
            <span className='text-gradient how-item'>Set your exam date, and let LearnNavigator.io automatically calculate your daily study goals, ensuring you're fully prepared for your exam (still in Beta).</span>
          </div>
        </li>
        <li className="list-item-3 font-gradient">
          <div className="list-flex-container">
          <span className="how-icon">🔭</span>
            <span className='text-gradient how-item'>Navigate through your notes concept-by-concept and mark your progress as you learn. The spaced repetition algorithm will make sure you learn exactly what's necessary.</span>
          </div>
        </li>
        <li className="list-item-3">
          <div className="list-flex-container font-gradient">
            <span className="how-icon">❓</span>
            <span className='text-gradient how-item'>If you marked the concept as 'Understood', LearnNavigator.io will test your active recall with automatically generated questions.</span>
          </div>
        </li>
      </ul>
      </div>
        </div>
      </div>
      <div className='w-layout-vflex flex-block-10'>
       <Slideshow/>
      </div>
      <div className="div-block-9">
      <div className="rectangle colorclass">
      <p className="review"><em>"I highly recommend LearnNavigator.io. The AI-generated flashcards are much better than competition. Overall, it's easy to understand and user-friendly."</em></p>
        <p className="signature">~👩 Laura, Bioinformatics</p>
        </div>
        <div className="rectangle colorclass">
        <p className="review"><em>"I can already see that LearnNavigator.io has made my study sessions much more productive. It’s like interactive learning at my own pace."</em></p>
        <p className="signature">~👩🏼‍🦰 Emilia, Buisiness Administration</p>
        </div>
        <div className="rectangle colorclass">
        <p className="review"><em>"I've found the blur-to-reveal feature very effective to quizz myself when I study. Rating my understanding helps me focus on weaker areas."</em></p>
        <p className="signature">~👨🏽 Vince, Medicine</p>
        </div>
      </div>
      <LandingSample style={{width:"100%"}} processedPages={numPages} materialID={materialId}/>
      
      <div className="div-block-4">
    <h2 className="heading-9"><span className="text-span-2 text-gradient">LearnNavigator.io vs Other Study Methods</span></h2>
    <table ref={captureRef} className="custom-table">
    <thead>
        <tr>
            <th>Benefits</th>
            <th>Flash<wbr></wbr>cards 👎</th>
            <th>Learning from Notes 😒</th>
            <th>Learn<wbr></wbr>Navigator 😃</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="benefit">Presents Complex Ideas in Context 🧠</td>
            <td><span class="cross">✘</span></td>
            <td><span class="tick">✔</span></td>
            <td><span class="tick">✔</span></td>
        </tr>
        <tr>
            <td class="benefit">Organizes Information Spatially 🗺️</td>
            <td><span class="cross">✘</span></td>
            <td><span class="tick">✔</span></td>
            <td><span class="tick">✔</span></td>
        </tr>
        <tr>
            <td class="benefit">Covers All Key Content ✅</td>
            <td><span class="cross">✘</span></td>
            <td><span class="tick">✔</span></td>
            <td><span class="tick">✔</span></td>
        </tr>
        <tr>
            <td class="benefit">Bite-Sized Learning 🧩</td>
            <td><span class="tick">✔</span></td>
            <td><span class="cross">✘</span></td>
            <td><span class="tick">✔</span></td>
        </tr>
        <tr>
            <td class="benefit">Simple and Clear Study Path 🛤️</td>
            <td><span class="tick">✔</span></td>
            <td><span class="cross">✘</span></td>
            <td><span class="tick">✔</span></td>
        </tr>
        <tr>
            <td class="benefit">Personalization and Instant Feedback💬</td>
            <td><span class="tick">✔</span></td>
            <td><span class="cross">✘</span></td>
            <td><span class="tick">✔</span></td>
        </tr>
        <tr>
            <td class="benefit">Boosts Focus 🤓</td>
            <td><span class="cross">✘</span></td>
            <td><span class="cross">✘</span></td>
            <td><span class="tick">✔</span></td>
        </tr>
    </tbody>
</table>
</div>
      <div className="div-block-4">
        <h2 className="heading-9"><span className="text-span-2 text-gradient">The 3-Step Learning Method<br/>Based on the Science of Learning</span></h2>
        <div className="w-layout-hflex flex-block-4">
          <div className="w-layout-vflex flex-block-6"><span className="three-step-icon">📖</span>
            <p className="paragraph"><strong className="bold-text-6 text-gradient">1. Read</strong></p>
          </div>
          <div className="w-layout-vflex flex-block-5"><span className="three-step-icon">💡</span>
            <p className="paragraph-2"><strong className="bold-text-3 text-gradient">2. Understand</strong></p>
          </div>
          <div className="w-layout-vflex flex-block-7"><span className="three-step-icon">🧠</span>
            <p className="paragraph-3"><strong className="bold-text-4 text-gradient">3. Actively Recall</strong></p>
          </div>
        </div>
      </div>
      <div className="w-layout-hflex flex-block-2">
  <FAQ/>
        <img src={slides} loading="lazy" alt="" className="image-17"/>
      </div>
      <div className="div-block-6">
        <h2 className="heading-7"><span className="text-span-2 text-gradient">Start Learning More Efficiently Now! </span></h2>
        <Button
      type="submit"
      onClick={goToRegister}
      variant="contained"
      sx={{
        background: 'linear-gradient(45deg, #006547, #004065)',
        boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)', // Multiple shadows for even effect
        '&:hover': {
          background: 'linear-gradient(45deg, #006547, #004065)', // Maintain the gradient background on hover
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)', // Glowing effect with multiple shadows
        },
        marginTop: '40px',
        color: '#ffffff', // Ensure text color is white
      }}
    >
      Register now for free!
    </Button>
      </div>
      <div className="w-layout-hflex flex-block-2">
        <img src={me} loading="lazy" alt="" className="image-20" />
        <div className="div-block-2 colorclass">
        <div className="div-block-2-internal">
          <h2 className="heading-10"><span className="text-span text-gradient">About The Founder</span></h2>
          <p className="about-me"><span className="text-gradient">
          Hi there </span>👋 <span className="text-gradient">My name is Michael Cherczynski, and I am the inventor and founder of LearnNavigator.io. I recently graduated from the Technical University of Munich in Quantum Computing and Business. And I was like: studying is way too inefficient and stressful </span>🤮<span className="text-gradient">.I decided to fix the way we learn by breaking complex concepts into small, manageable chunks.
           <br></br><br/>
          Now, to make it even better, you can drop your thoughts, ideas, comments, and critiques below!</span>
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center", gap: '20px', margin: '10px'}}>
            <TextField
        multiline
        rows={3}
        variant="standard"
        placeholder="Your feedback - it might just determine the future of education!"
        value={feedback} // Control component
        onChange={(e) => setFeedback(e.target.value)} // Update state on change
        sx={{
          width: '60%',
          paddingLeft: '10px',
          paddingRight: '10px',
          backgroundColor: 'white',
          borderRadius: '10px',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main',
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        onClick={sendFeedback} // Call sendFeedback on click
        sx={{
          background: 'linear-gradient(45deg, #006547, #004065)',
          boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)',
          '&:hover': { backgroundColor: '#E04A2F' ,
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)'}
      }}
      >
        Send
      </Button>
    </div>
    </div>
        </div>
       
      </div>
      <div className="div-block-6">
        <h2 className="heading-7"><span className="text-span-2 text-gradient" >Supported by:</span></h2>
        <div className='partners' >
        <img src ={CDTM} style = {{height: '150px'}}/>
        <img src ={UnternehmerTUM} style = {{height: '110px'}}/>
        <img src ={Microsoft} style = {{height: '110px'}}/>
        </div>
      </div>
    </div>
    <Footer></Footer>
    </div>
    </div>
  );
};

export default LandingPage;
