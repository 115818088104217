/* global dataLayer */
import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
//import { AuthProvider } from '../components/AuthContext';
//import ProtectedRoute from '../components/ProtectedRoute';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import LandingPage from './LandingPage';
import UserPage from './UserPage';
import MyMaterials from './MyMaterials';
import MyAccount from './MyAccount';
import ForgotPasswordPage from './ForgotPasswordPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsOfUse from './TermsOfUse';
import CookieConsent from "react-cookie-consent";
import './UserPage.css';
import CookiePolicyPage from './CookiePolicyPage';
import SciencePage from './SciencePage';

import ReactGA from 'react-ga4';
import { trackEvent } from '../tracking';


export function getCsrfToken() {
  return document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1];
}

export const loadMicrosoftClarity = () => {
  // Get the user ID from local storage
  const userId = getUserId();

  // If the user ID is 1, do not load the script
  if (userId === '1') {
    return;
  }

  // Create a script element
  const script = document.createElement('script');
  script.src = 'https://www.clarity.ms/tag/muy2xqah66';
  script.async = true;

  // Attach onload event to initialize Clarity
  script.onload = () => {
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "muy2xqah66");
  };

  // Attach onerror event to handle script load error
  script.onerror = () => {
  };

  // Append the script to the head
  document.head.appendChild(script);
};

// Assuming this function is defined somewhere in your project
export const getUserId = () => {
  return localStorage.getItem('userId');
};

export const loadGoogleAnalytics = () => {
  // Create a script element
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-5MRSDQ2VCN';
  script.async = true;
  document.head.appendChild(script);

  // Initialize Google Analytics after the script is loaded
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-5MRSDQ2VCN');
  };
};

function App() {
  function usePageTracking() {
  const location = useLocation();

  //Hook with a function to log navigating to new subpage into the Google Analytics
  useEffect(() => {
    //Gtag.pageview(location.pathname + location.search);
  }, [location]);}

 // useEffect(() =>{
  //  ReactGA.initialize('G-5MRSDQ2VCN');
   // loadGoogleAnalytics();
  //}, [])

  useEffect(() => {
    // Function to fetch CSRF token and ensure cookie is set
    const fetchCsrfToken = async () => {
      console.log('first fetch url:',`${process.env.REACT_APP_API_URL}accounts/csrf_token/` )
      const response = await fetch(`${process.env.REACT_APP_API_URL}accounts/csrf_token`, {
        credentials: 'include', mode: 'no-cors',  // Important for including cookies with the request
      });
      // No need to do anything with the response directly, 
      // as the CSRF token is automatically set as a cookie by Django
      if (response.status === 200) {
      }
    };

    fetchCsrfToken();
  }, []); // The empty array ensures this effect runs only once after the initial render

  useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent') === 'true';
    if (hasConsent){
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });}
  }, []);
  //usePageTracking();
  return (
    <div style={{ height: '100vh' }}>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        buttonStyle={{ background: "rgba(255,255,255,0.5)", borderRadius:'5px', color: "white", fontSize: "16px" }}
        declineButtonStyle={{ background: "transparent",borderRadius:'5px', color: "white", fontSize: "12px" }}
        enableDeclineButton
        onAccept={() => {
          
          ReactGA.initialize('G-5MRSDQ2VCN');
          //ReactGA.initialize('G-5MRSDQ2VCN');  IMPORTANT - some information wont be transmitted until logged in. Not sure which
          localStorage.setItem('cookieConsent', 'true');
          loadGoogleAnalytics();
          trackEvent('App', 'Accepted Cookie!');
        }}
        onDecline={() => {
          localStorage.setItem('cookieConsent', 'false');
          console.log('Cookies declined');
        }}
        cookieName="userConsentForCookies"
        style={{ background: "linear-gradient(45deg, #006547, #004065)" }}
        expires={150}
      >
        <span style={{ display: "block", marginTop: "10px" }}>
        We use cookies to improve grades of students around the world.
      Read our <a href="/cookie-policy" style={{ color: "#f9c571" }}>Cookie Policy</a> to learn more.
    </span>
      </CookieConsent>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/:materialId" element = {<LandingPage/>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
          <Route path="/why-it-works" element={<SciencePage/>}/>
          <Route path="/terms-of-use" element = {<TermsOfUse/>}/>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/cookie-policy" element={<CookiePolicyPage/>}></Route>
          <Route path="/current-session/:materialId" element={
              <UserPage />
          } />
          <Route path="/current-session" element={
              <UserPage />
          } />
          <Route path="/my-materials" element={
              <MyMaterials />
          } />
          <Route path="/my-account" element={
              <MyAccount />
          } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
