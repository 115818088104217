import React from "react";
//import myHtmlContent from './PrivacyPolicy.html' 
import './PrivacyPolicy_style.css'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

function PrivacyPolicyPage() {
    return(<div>
    <NavBar/>
        <div className='main-body' data-custom-class="body" style={{ marginTop: '64px', paddingTop: '50px', paddingBottom: '50px', paddingLeft: '15%', paddingRight: '15%' , backgroundImage: "url('./4850037.jpg')" , backgroundAttachment: "fixed"}}>
    <div > 
      <strong>
        <span style={{ fontSize: 26 }}>
          <span data-custom-class="title">
            <bdt className="block-component" />
            <bdt className="question">PRIVACY POLICY</bdt>
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="subtitle">
              Last updated <bdt className="question">March 26, 2024</bdt>
            </span>
          </span>
        </strong>
      </span>
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            This privacy notice for{" "}
            <bdt className="question">
              LearnNavigator.io
              <bdt className="block-component" />
            </bdt>{" "}
            (<bdt className="block-component" />"<strong>we</strong>," "
            <strong>us</strong>," or "<strong>our</strong>"
            <bdt className="statement-end-if-in-editor" />
          </span>
          <span data-custom-class="body_text">
            ), describes how and why we might collect, store, use, and/or share (
            <bdt className="block-component" />"<strong>process</strong>"
            <bdt className="statement-end-if-in-editor" />) your information when
            you use our services (<bdt className="block-component" />"
            <strong>Services</strong>"
            <bdt className="statement-end-if-in-editor" />
            ), such as when you:
          </span>
        </span>
      </span>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Visit our website
              <bdt className="block-component" /> at{" "}
              <span style={{ color: "rgb(0, 58, 250)" }}>
                <bdt className="question">learnnavigator.io</bdt>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor">
                          , or any website of ours that links to this privacy
                          notice
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </bdt>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Engage with us in other related ways, including any sales,
                marketing, or events
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
              notice will help you understand your privacy rights and choices. If
              you do not agree with our policies and practices, please do not use
              our Services.
              <bdt className="block-component" /> If you still have any questions
              or concerns, please contact us at{" "}
              <bdt className="question">learnnavigator@gmail.com</bdt>.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
          </span>
        </strong>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>
                This summary provides key points from our privacy notice, but you
                can find out more details about any of these topics by clicking
                the link following each key point or by using our&nbsp;
              </em>
            </strong>
          </span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>table of contents</em>
              </strong>
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>&nbsp;below to find the section you are looking for.</em>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services,
            the choices you make, and the products and features you use. Learn
            more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#personalinfo">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              personal information you disclose to us
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we process any sensitive personal information?</strong>{" "}
            <bdt className="block-component" />
            We may process sensitive personal information when necessary with your
            consent or as otherwise permitted by applicable law. Learn more
            about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#sensitiveinfo">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              sensitive information we process
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we receive any information from third parties?</strong>{" "}
            <bdt className="block-component" />
            We do not receive any information from third parties.
            <bdt className="else-block" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to comply
            with law. We may also process your information for other purposes with
            your consent. We process your information only when we have a valid
            legal reason to do so. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#infouse">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we process your information
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              In what situations and with which{" "}
              <bdt className="block-component" />
              parties do we share personal information?
            </strong>{" "}
            We may share information in specific situations and with specific{" "}
            <bdt className="block-component" />
            third parties. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#whoshare">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              when and with whom we share your personal information
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            .<bdt className="block-component" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we keep your information safe?</strong> We have{" "}
            <bdt className="block-component" />
            organizational
            <bdt className="statement-end-if-in-editor" /> and technical processes
            and procedures in place to protect your personal information. However,
            no electronic transmission over the internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or other{" "}
            <bdt className="block-component" />
            unauthorized
            <bdt className="statement-end-if-in-editor" /> third parties will not
            be able to defeat our security and improperly collect, access, steal,
            or modify your information. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we keep your information safe
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more
            about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#privacyrights">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">your privacy rights</span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by <bdt className="block-component" />
            visiting{" "}
            <span style={{ color: "rgb(0, 58, 250)" }}>
              <bdt className="question">learnnavigator@gmail.com</bdt>
            </span>
            <bdt className="else-block" />, or by contacting us. We will consider
            and act upon any request in accordance with applicable data protection
            laws.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            Want to learn more about what we do with any information we
            collect?&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              Review the privacy notice in full
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">.</span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="toc" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
              </strong>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infocollect">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              1. WHAT INFORMATION DO WE COLLECT?
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              2. HOW DO WE PROCESS YOUR INFORMATION?
              <bdt className="block-component" />
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#legalbases">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              3.{" "}
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </span>
              </span>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#whoshare">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#cookies">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#intltransfers">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#inforetain">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              8. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#privacyrights">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#DNT">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
              <bdt className="block-component" />
            </span>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </bdt>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#DNT">
          <bdt className="block-component">
            <span style={{ fontSize: 15 }} />
          </bdt>
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#DNT">
          <span style={{ fontSize: 15 }} />
        </a>
        <a data-custom-class="link" href="#policyupdates">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            11. DO WE MAKE UPDATES TO THIS NOTICE?
          </span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#contact">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#request">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="infocollect" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
              <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="personalinfo" style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>Personal information you disclose to us</strong>
          </span>
        </span>
      </div>
      <div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>
                        We collect personal information that you provide to us.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We collect personal information that you voluntarily provide to us
              when you{" "}
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
              register on the Services,&nbsp;
            </span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span data-custom-class="body_text">
                express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Personal Information Provided by You.</strong> The personal
              information that we collect depends on the context of your
              interactions with us and the Services, the choices you make, and the
              products and features you use. The personal information we collect
              may include the following:
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">email addresses</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">passwords</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">contact or authentication data</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Sensitive Information.</strong>{" "}
            <bdt className="block-component" />
            When necessary, with your consent or as otherwise permitted by
            applicable law, we process the following categories of sensitive
            information:
            <bdt className="forloop-component" />
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="question">student data</bdt>
            </span>
          </span>
        </li>
      </ul>
      <div>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="forloop-component" />
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="question">
                information revealing race or ethnic origin
              </bdt>
            </span>
          </span>
        </li>
      </ul>
      <div>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="forloop-component" />
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="question">
                information revealing religious or philosophical beliefs
              </bdt>
            </span>
          </span>
        </li>
      </ul>
      <div>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="forloop-component" />
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="question">study materials progress data</bdt>
            </span>
          </span>
        </li>
      </ul>
      <div>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="forloop-component" />
          </span>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </span>
          </span>
          <bdt className="block-component" />
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>Information automatically collected</strong>
          </span>
        </span>
      </div>
      <div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>
                        Some information — such as your Internet Protocol (IP)
                        address and/or browser and device characteristics — is
                        collected automatically when you visit our Services.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We automatically collect certain information when you visit, use, or
              navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Like many businesses, we also collect information through cookies
              and similar technologies. <bdt className="block-component" />
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="statement-end-if-in-editor">
                <span data-custom-class="body_text" />
              </bdt>
            </span>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              The information we collect includes:
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <em>Log and Usage Data.</em> Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services
                <span style={{ fontSize: 15 }}>&nbsp;</span>(such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called <bdt className="block-component" />
                "crash dumps"
                <bdt className="statement-end-if-in-editor" />
                ), and hardware settings).
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <bdt className="block-component">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text" />
          </span>
        </bdt>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <em>Device Data.</em> We collect device data such as information
                about your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers, location,
                browser type, hardware model, Internet service provider and/or
                mobile carrier, operating system, and system configuration
                information.
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <bdt className="block-component">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text" />
          </span>
        </bdt>
        <div>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }} />
          </bdt>
          <bdt className="statement-end-if-in-editor" />
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other purposes
                          with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.&nbsp;
                  </strong>
                  We may process your information so you can create and log in to
                  your account, as well as keep your account in working order.
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      To deliver and facilitate delivery of services to the
                      user.&nbsp;
                    </strong>
                    We may process your information to provide you with the
                    requested service.
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To respond to user inquiries/offer support to users.&nbsp;
                      </strong>
                      We may process your information to respond to your inquiries
                      and solve any potential issues you might have with the
                      requested service.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }} />
              </bdt>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To send administrative information to you.&nbsp;
                        </strong>
                        We may process your information to send you details about
                        our products and services, changes to our terms and
                        policies, and other similar information.
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text" />
                    </span>
                  </bdt>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <strong>
                          To <bdt className="block-component" />
                          fulfill
                          <bdt className="statement-end-if-in-editor" /> and
                          manage your orders.
                        </strong>{" "}
                        We may process your information to{" "}
                        <bdt className="block-component" />
                        fulfill
                        <bdt className="statement-end-if-in-editor" /> and manage
                        your orders, payments, returns, and exchanges made through
                        the Services.
                      </span>
                    </span>
                    <bdt className="statement-end-if-in-editor">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                      </span>
                    </bdt>
                  </li>
                </ul>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                </p>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                </p>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <bdt className="block-component" />
                </p>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <bdt className="block-component" />
                </p>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>To request feedback.&nbsp;</strong>We may
                            process your information when necessary to request
                            feedback and to contact you about your use of our
                            Services.
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text" />
                    </span>
                  </bdt>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </div>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>
                              To send you marketing and promotional
                              communications.&nbsp;
                            </strong>
                            We may process the personal information you send to us
                            for our marketing purposes, if this is in accordance
                            with your marketing preferences. You can opt out of
                            our marketing emails at any time. For more
                            information, see <bdt className="block-component" />"
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                      <a data-custom-class="link" href="#privacyrights">
                        <span style={{ fontSize: 15, color: "rgb(0, 58, 250)" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(0, 58, 250)" }}
                          >
                            <span data-custom-class="body_text">
                              WHAT ARE YOUR PRIVACY RIGHTS?
                            </span>
                          </span>
                        </span>
                      </a>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />"
                            <bdt className="statement-end-if-in-editor" /> below.
                          </span>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15 }}>
                        <bdt className="block-component">
                          <span data-custom-class="body_text" />
                        </bdt>
                      </span>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text" />
                          </span>
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <strong>
                                            To identify usage trends.
                                          </strong>{" "}
                                          We may process information about how you
                                          use our Services to better understand
                                          how they are being used so we can
                                          improve them.
                                        </span>
                                      </span>
                                      <bdt className="statement-end-if-in-editor">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                    </li>
                                  </ul>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <strong>
                                                  To save or protect an
                                                  individual's vital interest.
                                                </strong>{" "}
                                                We may process your information
                                                when necessary to save or protect
                                                an individual’s vital interest,
                                                such as to prevent harm.
                                              </span>
                                            </span>
                                            <bdt className="statement-end-if-in-editor">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div
                                          id="legalbases"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <strong>
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="heading_1">
                                                3. WHAT LEGAL BASES DO WE RELY ON
                                                TO PROCESS YOUR INFORMATION?
                                              </span>
                                            </span>
                                          </strong>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <em>
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text">
                                                <strong>In Short:&nbsp;</strong>We
                                                only process your personal
                                                information when we believe it is
                                                necessary and we have a valid
                                                legal reason (i.e.
                                                <bdt className="block-component" />
                                                ,
                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                legal basis) to do so under
                                                applicable law, like with your
                                                consent, to comply with laws, to
                                                provide you with services to enter
                                                into or{" "}
                                                <bdt className="block-component" />
                                                fulfill
                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                our contractual obligations, to
                                                protect your rights, or to{" "}
                                                <bdt className="block-component" />
                                                fulfill
                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                our legitimate business interests.
                                              </span>
                                            </span>
                                          </em>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              The General Data Protection
                                              Regulation (GDPR) and UK GDPR
                                              require us to explain the valid
                                              legal bases we rely on in order to
                                              process your personal information.
                                              As such, we may rely on the
                                              following legal bases to process
                                              your personal information:
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text">
                                                <strong>Consent.&nbsp;</strong>We
                                                may process your information if
                                                you have given us permission (i.e.
                                                <bdt className="block-component" />
                                                ,
                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                consent) to use your personal
                                                information for a specific
                                                purpose. You can withdraw your
                                                consent at any time. Learn more
                                                about&nbsp;
                                              </span>
                                            </span>
                                            <a
                                              data-custom-class="link"
                                              href="#withdrawconsent"
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(0, 58, 250)",
                                                  fontSize: 15
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  withdrawing your consent
                                                </span>
                                              </span>
                                            </a>
                                            <span data-custom-class="body_text">
                                              .
                                            </span>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <strong>
                                                  Performance of a Contract.
                                                </strong>{" "}
                                                We may process your personal
                                                information when we believe it is
                                                necessary to{" "}
                                                <bdt className="block-component" />
                                                fulfill
                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                our contractual obligations to
                                                you, including providing our
                                                Services or at your request prior
                                                to entering into a contract with
                                                you.
                                              </span>
                                            </span>
                                            <bdt className="statement-end-if-in-editor">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <strong>
                                                  Legitimate Interests.
                                                </strong>{" "}
                                                We may process your information
                                                when we believe it is reasonably
                                                necessary to achieve our
                                                legitimate business interests and
                                                those interests do not outweigh
                                                your interests and fundamental
                                                rights and freedoms. For example,
                                                we may process your personal
                                                information for some of the
                                                purposes described in order to:
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </div>
                                        <ul style={{ marginLeft: 40 }}>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                Send users information about
                                                special offers and discounts on
                                                our products and services
                                                <bdt className="statement-end-if-in-editor" />
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </div>
                                          <ul style={{ marginLeft: 40 }}>
                                            <li style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <bdt className="block-component" />
                                                  Analyze
                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                  how our Services are used so we
                                                  can improve them to engage and
                                                  retain users
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <bdt className="block-component" />
                                                </span>
                                              </span>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  <span style={{ fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                  </span>
                                                </span>
                                              </div>
                                              <ul style={{ marginLeft: 40 }}>
                                                <li style={{ lineHeight: "1.5" }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      Understand how our users use
                                                      our products and services so
                                                      we can improve user
                                                      experience
                                                      <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  <span style={{ fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                  </span>
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <bdt className="block-component">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <ul>
                                                <li style={{ lineHeight: "1.5" }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <strong>
                                                        Legal Obligations.
                                                      </strong>{" "}
                                                      We may process your
                                                      information where we believe
                                                      it is necessary for
                                                      compliance with our legal
                                                      obligations, such as to
                                                      cooperate with a law
                                                      enforcement body or
                                                      regulatory agency, exercise
                                                      or defend our legal rights,
                                                      or disclose your information
                                                      as evidence in litigation in
                                                      which we are involved.
                                                      <bdt className="statement-end-if-in-editor" />
                                                      <br />
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <ul>
                                                <li style={{ lineHeight: "1.5" }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <strong>
                                                        Vital Interests.
                                                      </strong>{" "}
                                                      We may process your
                                                      information where we believe
                                                      it is necessary to protect
                                                      your vital interests or the
                                                      vital interests of a third
                                                      party, such as situations
                                                      involving potential threats
                                                      to the safety of any person.
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </li>
                                              </ul>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <span data-custom-class="body_text">
                                                  <span style={{ fontSize: 15 }}>
                                                    <bdt className="block-component">
                                                      <bdt className="block-component" />
                                                    </bdt>
                                                  </span>
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <br />
                                              </div>
                                              <div
                                                id="whoshare"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color: "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              4. WHEN AND WITH
                                                              WHOM DO WE SHARE
                                                              YOUR PERSONAL
                                                              INFORMATION?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <br />
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <em>In Short:</em>
                                                      </strong>
                                                      <em>
                                                        &nbsp;We may share
                                                        information in specific
                                                        situations described in
                                                        this section and/or with
                                                        the following{" "}
                                                        <bdt className="block-component" />
                                                        third parties.
                                                      </em>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <br />
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      Vendors, Consultants, and
                                                      Other Third-Party Service
                                                      Providers.
                                                    </strong>{" "}
                                                    We may share your data with
                                                    third-party vendors, service
                                                    providers, contractors, or
                                                    agents (
                                                    <bdt className="block-component" />
                                                    "
                                                    <strong>third parties</strong>
                                                    "
                                                    <bdt className="statement-end-if-in-editor" />
                                                    ) who perform services for us
                                                    or on our behalf and require
                                                    access to such information to
                                                    do that work.{" "}
                                                    <bdt className="block-component" />
                                                    We have contracts in place
                                                    with our third parties, which
                                                    are designed to help safeguard
                                                    your personal information.
                                                    This means that they cannot do
                                                    anything with your personal
                                                    information unless we have
                                                    instructed them to do it. They
                                                    will also not share your
                                                    personal information with any{" "}
                                                    <bdt className="block-component" />
                                                    organization
                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                    apart from us. They also
                                                    commit to protect the data
                                                    they hold on our behalf and to
                                                    retain it for the period we
                                                    instruct.{" "}
                                                    <bdt className="statement-end-if-in-editor" />
                                                    The{" "}
                                                    <bdt className="block-component" />
                                                    third parties we may share
                                                    personal information with are
                                                    as follows:
                                                  </span>
                                                </span>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                                <span data-custom-class="body_text">
                                                  <bdt className="block-component" />
                                                </span>
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </div>
                                              <ul>
                                                <li style={{ lineHeight: "1.5" }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          Cloud Computing Services
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div style={{ marginLeft: 40 }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="forloop-component">
                                                            <span data-custom-class="body_text" />
                                                          </bdt>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                                <bdt className="question">
                                                                  Amazon Web
                                                                  Services (AWS)
                                                                </bdt>
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <bdt className="forloop-component" />
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </div>
                                              <ul>
                                                <li style={{ lineHeight: "1.5" }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          Web and Mobile Analytics
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div style={{ marginLeft: 40 }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="forloop-component">
                                                            <span data-custom-class="body_text" />
                                                          </bdt>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                                <bdt className="question">
                                                                  Google Analytics
                                                                </bdt>
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <bdt className="forloop-component" />
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                                <span data-custom-class="body_text" />
                                                <span data-custom-class="body_text" />
                                                <span data-custom-class="body_text" />
                                                <span data-custom-class="body_text" />
                                                <span data-custom-class="body_text" />
                                                <span data-custom-class="body_text" />
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <br />
                                              </div>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    We{" "}
                                                    <bdt className="block-component" />
                                                    also{" "}
                                                    <bdt className="statement-end-if-in-editor" />
                                                    may need to share your
                                                    personal information in the
                                                    following situations:
                                                  </span>
                                                </span>
                                              </div>
                                              <ul>
                                                <li style={{ lineHeight: "1.5" }}>
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        Business Transfers.
                                                      </strong>{" "}
                                                      We may share or transfer
                                                      your information in
                                                      connection with, or during
                                                      negotiations of, any merger,
                                                      sale of company assets,
                                                      financing, or acquisition of
                                                      all or a portion of our
                                                      business to another company.
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  />
                                                </bdt>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                  <div
                                                    style={{ lineHeight: "1.5" }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span data-custom-class="body_text" />
                                                      </bdt>
                                                    </span>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{ fontSize: 15 }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                        </bdt>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component">
                                                                        <span data-custom-class="heading_1" />
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="cookies"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          5. DO WE
                                                                          USE
                                                                          COOKIES
                                                                          AND
                                                                          OTHER
                                                                          TRACKING
                                                                          TECHNOLOGIES?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;We may
                                                                    use cookies
                                                                    and other
                                                                    tracking
                                                                    technologies
                                                                    to collect and
                                                                    store your
                                                                    information.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We may use
                                                                  cookies and
                                                                  similar tracking
                                                                  technologies
                                                                  (like web
                                                                  beacons and
                                                                  pixels) to
                                                                  access or store
                                                                  information.
                                                                  Specific
                                                                  information
                                                                  about how we use
                                                                  such
                                                                  technologies and
                                                                  how you can
                                                                  refuse certain
                                                                  cookies is set
                                                                  out in our
                                                                  Cookie Notice
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      .
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                                <bdt className="block-component">
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="intltransfers"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          6. IS
                                                                          YOUR
                                                                          INFORMATION
                                                                          TRANSFERRED
                                                                          INTERNATIONALLY?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We may
                                                                    transfer,
                                                                    store, and
                                                                    process your
                                                                    information in
                                                                    countries
                                                                    other than
                                                                    your own.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Our servers are
                                                                  located in
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        . If you
                                                                        are
                                                                        accessing
                                                                        our
                                                                        Services
                                                                        from
                                                                        outside
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="forloop-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        , please
                                                                        be aware
                                                                        that your
                                                                        information
                                                                        may be
                                                                        transferred
                                                                        to,
                                                                        stored,
                                                                        and
                                                                        processed
                                                                        by us in
                                                                        our
                                                                        facilities
                                                                        and by
                                                                        those
                                                                        third
                                                                        parties
                                                                        with whom
                                                                        we may
                                                                        share your
                                                                        personal
                                                                        information
                                                                        (see{" "}
                                                                        <bdt className="block-component" />
                                                                        "
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <a
                                                                    data-custom-class="link"
                                                                    href="#whoshare"
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(0, 58, 250)"
                                                                        }}
                                                                      >
                                                                        WHEN AND
                                                                        WITH WHOM
                                                                        DO WE
                                                                        SHARE YOUR
                                                                        PERSONAL
                                                                        INFORMATION?
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                        "
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        above), in
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        &nbsp;and
                                                                        other
                                                                        countries.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are a
                                                                  resident in the
                                                                  European
                                                                  Economic Area
                                                                  (EEA), United
                                                                  Kingdom (UK), or
                                                                  Switzerland,
                                                                  then these
                                                                  countries may
                                                                  not necessarily
                                                                  have data
                                                                  protection laws
                                                                  or other similar
                                                                  laws as
                                                                  comprehensive as
                                                                  those in your
                                                                  country.
                                                                  However, we will
                                                                  take all
                                                                  necessary
                                                                  measures to
                                                                  protect your
                                                                  personal
                                                                  information in
                                                                  accordance with
                                                                  this privacy
                                                                  notice and
                                                                  applicable law.
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="inforetain"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          7. HOW
                                                                          LONG DO
                                                                          WE KEEP
                                                                          YOUR
                                                                          INFORMATION?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We keep your
                                                                    information
                                                                    for as long as
                                                                    necessary to{" "}
                                                                    <bdt className="block-component" />
                                                                    fulfill
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    the purposes
                                                                    outlined in
                                                                    this privacy
                                                                    notice unless
                                                                    otherwise
                                                                    required by
                                                                    law.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We will only
                                                                  keep your
                                                                  personal
                                                                  information for
                                                                  as long as it is
                                                                  necessary for
                                                                  the purposes set
                                                                  out in this
                                                                  privacy notice,
                                                                  unless a longer
                                                                  retention period
                                                                  is required or
                                                                  permitted by law
                                                                  (such as tax,
                                                                  accounting, or
                                                                  other legal
                                                                  requirements).
                                                                  <bdt className="block-component" />{" "}
                                                                  No purpose in
                                                                  this notice will
                                                                  require us
                                                                  keeping your
                                                                  personal
                                                                  information for
                                                                  longer than{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                    &nbsp;
                                                                  </span>{" "}
                                                                  <bdt className="block-component" />
                                                                  <bdt className="question">
                                                                    six (6)
                                                                  </bdt>{" "}
                                                                  months past the
                                                                  termination of
                                                                  the user's
                                                                  account
                                                                  <bdt className="block-component" />
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="else-block" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                                &nbsp;
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  When we have no
                                                                  ongoing
                                                                  legitimate
                                                                  business need to
                                                                  process your
                                                                  personal
                                                                  information, we
                                                                  will either
                                                                  delete or{" "}
                                                                  <bdt className="block-component" />
                                                                  anonymize
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  such
                                                                  information, or,
                                                                  if this is not
                                                                  possible (for
                                                                  example, because
                                                                  your personal
                                                                  information has
                                                                  been stored in
                                                                  backup
                                                                  archives), then
                                                                  we will securely
                                                                  store your
                                                                  personal
                                                                  information and
                                                                  isolate it from
                                                                  any further
                                                                  processing until
                                                                  deletion is
                                                                  possible.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="infosafe"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          8. HOW
                                                                          DO WE
                                                                          KEEP
                                                                          YOUR
                                                                          INFORMATION
                                                                          SAFE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We aim to
                                                                    protect your
                                                                    personal
                                                                    information
                                                                    through a
                                                                    system of{" "}
                                                                    <bdt className="block-component" />
                                                                    organizational
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    and technical
                                                                    security
                                                                    measures.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We have
                                                                  implemented
                                                                  appropriate and
                                                                  reasonable
                                                                  technical and{" "}
                                                                  <bdt className="block-component" />
                                                                  organizational
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  security
                                                                  measures
                                                                  designed to
                                                                  protect the
                                                                  security of any
                                                                  personal
                                                                  information we
                                                                  process.
                                                                  However, despite
                                                                  our safeguards
                                                                  and efforts to
                                                                  secure your
                                                                  information, no
                                                                  electronic
                                                                  transmission
                                                                  over the
                                                                  Internet or
                                                                  information
                                                                  storage
                                                                  technology can
                                                                  be guaranteed to
                                                                  be 100% secure,
                                                                  so we cannot
                                                                  promise or
                                                                  guarantee that
                                                                  hackers,
                                                                  cybercriminals,
                                                                  or other{" "}
                                                                  <bdt className="block-component" />
                                                                  unauthorized
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  third parties
                                                                  will not be able
                                                                  to defeat our
                                                                  security and
                                                                  improperly
                                                                  collect, access,
                                                                  steal, or modify
                                                                  your
                                                                  information.
                                                                  Although we will
                                                                  do our best to
                                                                  protect your
                                                                  personal
                                                                  information,
                                                                  transmission of
                                                                  personal
                                                                  information to
                                                                  and from our
                                                                  Services is at
                                                                  your own risk.
                                                                  You should only
                                                                  access the
                                                                  Services within
                                                                  a secure
                                                                  environment.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="privacyrights"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          9. WHAT
                                                                          ARE YOUR
                                                                          PRIVACY
                                                                          RIGHTS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <bdt className="block-component" />
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    In some
                                                                    regions, such
                                                                    as{" "}
                                                                    <bdt className="block-component" />
                                                                    the European
                                                                    Economic Area
                                                                    (EEA), United
                                                                    Kingdom (UK),
                                                                    and
                                                                    Switzerland
                                                                    <bdt className="block-component" />
                                                                    , you have
                                                                    rights that
                                                                    allow you
                                                                    greater access
                                                                    to and control
                                                                    over your
                                                                    personal
                                                                    information.
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                      &nbsp;
                                                                    </span>
                                                                    You may
                                                                    review,
                                                                    change, or
                                                                    terminate your
                                                                    account at any
                                                                    time.
                                                                  </em>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  In some regions
                                                                  (like{" "}
                                                                  <bdt className="block-component" />
                                                                  the EEA, UK, and
                                                                  Switzerland
                                                                  <bdt className="block-component" />
                                                                  ), you have
                                                                  certain rights
                                                                  under applicable
                                                                  data protection
                                                                  laws. These may
                                                                  include the
                                                                  right (i) to
                                                                  request access
                                                                  and obtain a
                                                                  copy of your
                                                                  personal
                                                                  information,
                                                                  (ii) to request
                                                                  rectification or
                                                                  erasure; (iii)
                                                                  to restrict the
                                                                  processing of
                                                                  your personal
                                                                  information;
                                                                  (iv) if
                                                                  applicable, to
                                                                  data
                                                                  portability; and
                                                                  (v) not to be
                                                                  subject to
                                                                  automated
                                                                  decision-making.
                                                                  In certain
                                                                  circumstances,
                                                                  you may also
                                                                  have the right
                                                                  to object to the
                                                                  processing of
                                                                  your personal
                                                                  information. You
                                                                  can make such a
                                                                  request by
                                                                  contacting us by
                                                                  using the
                                                                  contact details
                                                                  provided in the
                                                                  section{" "}
                                                                  <bdt className="block-component" />
                                                                  "
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(0, 58, 250)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(0, 58, 250)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    HOW CAN YOU
                                                                    CONTACT US
                                                                    ABOUT THIS
                                                                    NOTICE?
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                  "
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  below.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We will consider
                                                                  and act upon any
                                                                  request in
                                                                  accordance with
                                                                  applicable data
                                                                  protection laws.
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              &nbsp;
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  located in the
                                                                  EEA or UK and
                                                                  you believe we
                                                                  are unlawfully
                                                                  processing your
                                                                  personal
                                                                  information, you
                                                                  also have the
                                                                  right to
                                                                  complain to your{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 58, 250)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 58, 250)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <a
                                                                              data-custom-class="link"
                                                                              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                Member
                                                                                State
                                                                                data
                                                                                protection
                                                                                authority
                                                                              </span>
                                                                            </a>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>{" "}
                                                                  or&nbsp;
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(0, 58, 250)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(0, 58, 250)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    UK data
                                                                    protection
                                                                    authority
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  located in
                                                                  Switzerland, you
                                                                  may contact the{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 58, 250)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 58, 250)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 58, 250)",
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <a
                                                                                data-custom-class="link"
                                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                              >
                                                                                Federal
                                                                                Data
                                                                                Protection
                                                                                and
                                                                                Information
                                                                                Commissioner
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="withdrawconsent"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      Withdrawing
                                                                      your
                                                                      consent:
                                                                    </u>
                                                                  </strong>{" "}
                                                                  If we are
                                                                  relying on your
                                                                  consent to
                                                                  process your
                                                                  personal
                                                                  information,
                                                                  <bdt className="block-component" />
                                                                  <bdt className="else-block" />{" "}
                                                                  you have the
                                                                  right to
                                                                  withdraw your
                                                                  consent at any
                                                                  time. You can
                                                                  withdraw your
                                                                  consent at any
                                                                  time by
                                                                  contacting us by
                                                                  using the
                                                                  contact details
                                                                  provided in the
                                                                  section{" "}
                                                                  <bdt className="block-component" />
                                                                  "
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(0, 58, 250)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(0, 58, 250)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    HOW CAN YOU
                                                                    CONTACT US
                                                                    ABOUT THIS
                                                                    NOTICE?
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                  "
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  below
                                                                  <bdt className="block-component" />
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                However, please
                                                                note that this
                                                                will not affect
                                                                the lawfulness of
                                                                the processing
                                                                before its
                                                                withdrawal nor,
                                                                <bdt className="block-component" />
                                                                <bdt className="else-block" />{" "}
                                                                will it affect the
                                                                processing of your
                                                                personal
                                                                information
                                                                conducted in
                                                                reliance on lawful
                                                                processing grounds
                                                                other than
                                                                consent.
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <u>
                                                                    Opting out of
                                                                    marketing and
                                                                    promotional
                                                                    communications:
                                                                  </u>
                                                                </strong>
                                                                <strong>
                                                                  <u>&nbsp;</u>
                                                                </strong>
                                                                You can
                                                                unsubscribe from
                                                                our marketing and
                                                                promotional
                                                                communications at
                                                                any time by
                                                                <bdt className="block-component" />{" "}
                                                                clicking on the
                                                                unsubscribe link
                                                                in the emails that
                                                                we send,
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <bdt className="block-component" />
                                                                <bdt className="block-component" />{" "}
                                                                or by contacting
                                                                us using the
                                                                details provided
                                                                in the section{" "}
                                                                <bdt className="block-component" />
                                                                "
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(0, 58, 250)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  HOW CAN YOU
                                                                  CONTACT US ABOUT
                                                                  THIS NOTICE?
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                "
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                below. You will
                                                                then be removed
                                                                from the marketing
                                                                lists. However, we
                                                                may still
                                                                communicate with
                                                                you — for example,
                                                                to send you
                                                                service-related
                                                                messages that are
                                                                necessary for the
                                                                administration and
                                                                use of your
                                                                account, to
                                                                respond to service
                                                                requests, or for
                                                                other
                                                                non-marketing
                                                                purposes.
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="heading_2">
                                                                <strong>
                                                                  Account
                                                                  Information
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If you would at
                                                                any time like to
                                                                review or change
                                                                the information in
                                                                your account or
                                                                terminate your
                                                                account, you can:
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight: "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <bdt className="question">
                                                                    Contact us
                                                                    using the
                                                                    contact
                                                                    information
                                                                    provided.
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Upon your request
                                                                to terminate your
                                                                account, we will
                                                                deactivate or
                                                                delete your
                                                                account and
                                                                information from
                                                                our active
                                                                databases.
                                                                However, we may
                                                                retain some
                                                                information in our
                                                                files to prevent
                                                                fraud,
                                                                troubleshoot
                                                                problems, assist
                                                                with any
                                                                investigations,
                                                                enforce our legal
                                                                terms and/or
                                                                comply with
                                                                applicable legal
                                                                requirements.
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      Cookies and
                                                                      similar
                                                                      technologies:
                                                                    </u>
                                                                  </strong>{" "}
                                                                  Most Web
                                                                  browsers are set
                                                                  to accept
                                                                  cookies by
                                                                  default. If you
                                                                  prefer, you can
                                                                  usually choose
                                                                  to set your
                                                                  browser to
                                                                  remove cookies
                                                                  and to reject
                                                                  cookies. If you
                                                                  choose to remove
                                                                  cookies or
                                                                  reject cookies,
                                                                  this could
                                                                  affect certain
                                                                  features or
                                                                  services of our
                                                                  Services.{" "}
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If you have
                                                                questions or
                                                                comments about
                                                                your privacy
                                                                rights, you may
                                                                email us at{" "}
                                                                <bdt className="question">
                                                                  learnnavigator@gmail.com
                                                                </bdt>
                                                                .
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="DNT"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          10.
                                                                          CONTROLS
                                                                          FOR
                                                                          DO-NOT-TRACK
                                                                          FEATURES
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Most web
                                                                  browsers and
                                                                  some mobile
                                                                  operating
                                                                  systems and
                                                                  mobile
                                                                  applications
                                                                  include a
                                                                  Do-Not-Track (
                                                                  <bdt className="block-component" />
                                                                  "DNT"
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                  ) feature or
                                                                  setting you can
                                                                  activate to
                                                                  signal your
                                                                  privacy
                                                                  preference not
                                                                  to have data
                                                                  about your
                                                                  online browsing
                                                                  activities
                                                                  monitored and
                                                                  collected. At
                                                                  this stage no
                                                                  uniform
                                                                  technology
                                                                  standard for{" "}
                                                                  <bdt className="block-component" />
                                                                  recognizing
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  and implementing
                                                                  DNT signals has
                                                                  been{" "}
                                                                  <bdt className="block-component" />
                                                                  finalized
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                  . As such, we do
                                                                  not currently
                                                                  respond to DNT
                                                                  browser signals
                                                                  or any other
                                                                  mechanism that
                                                                  automatically
                                                                  communicates
                                                                  your choice not
                                                                  to be tracked
                                                                  online. If a
                                                                  standard for
                                                                  online tracking
                                                                  is adopted that
                                                                  we must follow
                                                                  in the future,
                                                                  we will inform
                                                                  you about that
                                                                  practice in a
                                                                  revised version
                                                                  of this privacy
                                                                  notice.
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              />
                                                            </bdt>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              />
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="policyupdates"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          11. DO
                                                                          WE MAKE
                                                                          UPDATES
                                                                          TO THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <em>
                                                                    <strong>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </strong>
                                                                    Yes, we will
                                                                    update this
                                                                    notice as
                                                                    necessary to
                                                                    stay compliant
                                                                    with relevant
                                                                    laws.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We may update
                                                                  this privacy
                                                                  notice from time
                                                                  to time. The
                                                                  updated version
                                                                  will be
                                                                  indicated by an
                                                                  updated{" "}
                                                                  <bdt className="block-component" />
                                                                  "Revised"
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  date and the
                                                                  updated version
                                                                  will be
                                                                  effective as
                                                                  soon as it is
                                                                  accessible. If
                                                                  we make material
                                                                  changes to this
                                                                  privacy notice,
                                                                  we may notify
                                                                  you either by
                                                                  prominently
                                                                  posting a notice
                                                                  of such changes
                                                                  or by directly
                                                                  sending you a
                                                                  notification. We
                                                                  encourage you to
                                                                  review this
                                                                  privacy notice
                                                                  frequently to be
                                                                  informed of how
                                                                  we are
                                                                  protecting your
                                                                  information.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="contact"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          12. HOW
                                                                          CAN YOU
                                                                          CONTACT
                                                                          US ABOUT
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you have
                                                                  questions or
                                                                  comments about
                                                                  this notice, you
                                                                  may{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component">
                                                                        <bdt className="block-component" />
                                                                      </bdt>
                                                                      email us at{" "}
                                                                      <bdt className="question">
                                                                        learnnavigator@gmail.com
                                                                        or&nbsp;
                                                                      </bdt>
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <bdt className="block-component" />
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        contact us
                                                                        by post
                                                                        at:
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="question">
                                                                            LearnNavigator.io
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="question">
                                                                  Grasmeierstrasse
                                                                  9
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="question">
                                                                  Munich
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                    ,{" "}
                                                                    <bdt className="question">
                                                                      Bavaria
                                                                    </bdt>
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />{" "}
                                                                    <bdt className="question">
                                                                      80805
                                                                    </bdt>
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="question">
                                                                  Germany
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <bdt className="statement-end-if-in-editor">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </bdt>
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component">
                                                                                <bdt className="block-component" />
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="statement-end-if-in-editor">
                                                                            <bdt className="block-component" />
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="request"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          13. HOW
                                                                          CAN YOU
                                                                          REVIEW,
                                                                          UPDATE,
                                                                          OR
                                                                          DELETE
                                                                          THE DATA
                                                                          WE
                                                                          COLLECT
                                                                          FROM
                                                                          YOU?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                  Based on the
                                                                  applicable laws
                                                                  of your country,
                                                                  you may have the
                                                                  right to request
                                                                  access to the
                                                                  personal
                                                                  information we
                                                                  collect from
                                                                  you, change that
                                                                  information, or
                                                                  delete it.{" "}
                                                                  <bdt className="else-block">
                                                                    <bdt className="block-component" />
                                                                    To request to
                                                                    review,
                                                                    update, or
                                                                    delete your
                                                                    personal
                                                                    information,
                                                                    please{" "}
                                                                    <bdt className="block-component" />
                                                                  </bdt>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  visit:{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 58, 250)"
                                                                    }}
                                                                  >
                                                                    <bdt className="question">
                                                                      learnnavigator@gmail.com
                                                                    </bdt>
                                                                  </span>
                                                                  <bdt className="else-block" />
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                .
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <style
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                "\n        ul {\n          list-style-type: square;\n        }\n        ul > li > ul {\n          list-style-type: circle;\n        }\n        ul > li > ul > li > ul {\n          list-style-type: square;\n        }\n        ol li {\n          font-family: Arial ;\n        }\n      "
                                                            }}
                                                          />
                                                        </div>
                                                        <div
                                                          style={{
                                                            color: "#595959",
                                                            fontSize: 14,
                                                            fontFamily: "Arial",
                                                            paddingTop: 16
                                                          }}
                                                        >
                                                          This privacy policy was
                                                          created using Termly's{" "}
                                                          <a
                                                            style={{
                                                              color:
                                                                "rgb(48, 48, 241) !important"
                                                            }}
                                                            href="https://termly.io/products/privacy-policy-generator/"
                                                          >
                                                            Privacy Policy
                                                            Generator
                                                          </a>
                                                          .
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
  </div>)
  ;
  }
  

export default PrivacyPolicyPage;

