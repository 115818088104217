import React from "react";
//import myHtmlContent from './PrivacyPolicy.html' 
import './SciencePage.css'
import  './css/learnnavigator.webflow.css';

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Box } from "@mui/material";


function SciencePage() {
    return(<div>
    <NavBar/>
    <div data-custom-class="body" style={{ marginTop: '64px', paddingTop: '50px', paddingBottom: '50px', paddingLeft: '15%', paddingRight: '15%' , backgroundImage: "url('./4850037.jpg')" , backgroundAttachment: "fixed"}}>
    <Box className="science-page" sx={{backgroundColor:"#006547"}}>
<div className="div-block-2-internal" style={{backgroundColor:"#006547"}}>
          <h2 className="heading-10"><span className="text-span">Why does it work - the Science behind learning and LearnNavigator.io 🤔</span></h2>
          <h7 className="list-flex-container">Learning requires the following steps: </h7>
          <ul role="list" className="list">
        <li className="list-item">
          <div className="list-flex-container">
            <span className="how-icon">📤</span>
            <span>Upload your study materials in PDF format and let LearnNavigator.io divide it into small, digestible concepts.</span>
          </div>
        </li>
        <li className="list-item-3">
          <div className="list-flex-container">
          <span className="how-icon">🔭</span>
            <span>Navigate through your material concept-by-concept, learn by revealing the concepts, and mark your progress.</span>
          </div>
        </li>
        <li className="list-item-3">
          <div className="list-flex-container">
            <span className="how-icon">❓</span>
            <span>Test your active recall with automatically generated questions to each concept.</span>
          </div>
        </li>
      </ul>
      </div>
    </Box>
    </div>
  <Footer/>
  </div>)
  ;
  }
  

export default SciencePage;

