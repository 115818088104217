export function extractSnippetStatuses(inputData) {
    // Map over each page to transform it into an array of snippet statuses
    const snippetStatusArrays = inputData.snippets.map(page => 
      page.snippets.map(snippet => snippet.status)
    );
  
    return snippetStatusArrays;
  }

export function extractSnippetDates(inputData){

const snippetDatesArray = inputData.snippets.map(page=>
    page.snippets.map(snippet => snippet.last_learned_date)
    );

    return snippetDatesArray;
}

export function extractSnippetQuestions(inputData){
const snippetQuestionsArray = inputData.snippets.map(page =>
page.snippets.map(snippet => snippet.question));
return snippetQuestionsArray
}

export function convertSnippets(inputData, pageNumber) {
    // Find the page object that matches the given pageNumber
    const pageData = inputData.find(page => page.page === pageNumber);
  
    // Check if the page exists
    if (!pageData) {
      console.error(`Page number ${pageNumber} not found.`);
      return [];
    }
  
    // Map each snippet's content to a new array, attempting to convert it to valid JSON and then parse
    const snippetsContent = pageData.snippets.map(snippet => {
      // Attempt to convert tuple-like string to JSON string
      const jsonString = snippet.content
        .replace(/\(/g, '[') // Replace opening parentheses with square brackets
        .replace(/\)/g, ']'); // Replace closing parentheses with square brackets
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        console.error(`Error parsing JSON from snippet content: ${jsonString}`, error);
        return []; // Return an empty array or some error indication as needed
      }
    });
  
    return snippetsContent;
  }

export function flattenDeep(arr) {
    let result = [];

    // Define a helper function for recursion
    function flatten(item) {
        if (Array.isArray(item)) {
            // Check if the array is a 4-number list
            if (item.length === 4 && item.every(num => typeof num === 'number')) {
                result.push(item);
            } else {
                // If not, recursively flatten each item in this array
                item.forEach(flatten);
            }
        }
    }

    // Start the recursion with the initial array
    arr.forEach(flatten);

    return result;
}

export function drawSnippets(ctx, snippets, boundingBoxes, scale, nCurrentSnippets) {
    let i = 0;
    boundingBoxes.forEach((snippet, snippetIndex) => {
      snippet.forEach((bbox, bboxIndex) => {
        const [x0, y0, x1, y1] = bbox;
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, x0 * scale, y0 * scale, (x1 - x0) * scale, (y1 - y0) * scale);
          if (snippetIndex === nCurrentSnippets - 1) {  // Highlight the last snippet
            ctx.strokeStyle = 'green';
            ctx.lineWidth = 3;
            ctx.strokeRect(x0 * scale, y0 * scale, (x1 - x0) * scale, (y1 - y0) * scale);
          }
        };
        img.src = snippets[i];
        if (i < snippets.length) i++;
      });
    });
  }