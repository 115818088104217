// src/pages/ForgotPasswordPage.js

import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here, implement your logic to handle password reset, e.g., sending the email to the server
    console.log(email);
    // Optionally, navigate to another page or show a success message
  };

  return (
    <div><NavBar/>
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '64px', justifyContent: 'center', height: '100vh', backgroundImage: "url('./4850037.jpg')" , backgroundAttachment: "fixed" 
       // Using the app's color scheme
    }}>
      
      <Paper elevation={3} sx={{backgroundColor: '#0D5842', padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
        <Typography variant="h5" color="#f9c571">Reset Your Password</Typography>
        <Typography variant="body1" gutterBottom sx={{color: 'white'}}>
          Enter your email to receive password reset instructions.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%', maxWidth: '300px' }}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            sx={{
              '& .MuiInputBase-input': { backgroundColor: 'white' }, // Applies white background to the input field
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }, // Optional: makes border transparent
            }}
          />
          <Button type="submit" variant="contained" sx={{ backgroundColor: '#f9c571', '&:hover': { backgroundColor: '#E04A2F' } }}>
            Send Instructions
          </Button>
        </Box>
      </Paper>
    </Box>
    <Footer/>
    </div>
  );
};

export default ForgotPasswordPage;
