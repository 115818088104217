import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddMaterialButton from '../components/AddMaterialButton';
import { trackEvent } from "../tracking.js";
import { CircularProgress, Typography, Button} from '@mui/material';
import { useLocation } from 'react-router-dom';
import UserPage from '../pages/UserPage.js';
import  '../pages/css/learnnavigator.webflow.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const LandingSample = ({materialID = null, processedPages = null}) => {
    const query = useQuery();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [numPages, setNumPage] = useState(0);
    const location = useLocation();
    
    useEffect(() => {
        // This effect runs whenever the location (specifically search part) changes.
        const processed = query.get('processed');
        setNumPage(processed);
    }, [location.search]); // Dependency on search ensures effect runs when query params change

    const goToRegister = () => {
        navigate('/register');
      };

    const handleFileChange = (event) => {
        trackEvent('Document', 'started_uploading_document_sample');
        const file = event.target.files[0];
        if (file) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('pdfFile', file);
        formData.append('fileName', file.name);
    
        fetch(`${process.env.REACT_APP_API_URL}app/modify-pdf`, {
            method: 'POST',
            body: formData,
            headers: {
            },
            credentials: 'include',
        })
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data.message);
            console.log('data', data);
            const materialId = data.materialId;
            const nTotal = data.docLen;
            setTotalPage(nTotal);
            setNumPage(1);
            processEachPage(data.materialId, 0, nTotal);
            // Navigate to UserPage with the materialId and processed query parameters
        })
        .catch(error => {
            console.error(error);
        });
        }
    };
    
    const processEachPage = (materialId, currentPage, totalPages) => {
        // Check if the current page exceeds 50 or if it's the last page to process
        if (currentPage >= totalPages || currentPage >= 3) {
            setIsLoading(false); // Stop loading
            console.log('All pages processed or max limit reached');
            return;
        }
      
        const formData = new FormData();
        formData.append('material_id', materialId);
        formData.append('page_num', currentPage);
      
        fetch(`${process.env.REACT_APP_API_URL}app/process-page-backend`, {
            method: 'POST',
            body: formData,
            headers: {
            },
            credentials: 'include',
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data.message);
            setNumPage(currentPage + 1);
      
            // Navigate to UserPage with the updated 'processed' count after 3 pages have been processed
            if (currentPage >= 0) { // starts updating the navigation path from the third page
                navigate(`/${materialId}?processed=${currentPage + 1}`);
            }
      
            // Continue processing the next page
            processEachPage(materialId, currentPage + 1, totalPages);
        })
        .catch(error => {
            console.error(error);
        });
      };

    const addMaterial = () => { fileInputRef.current.click(); };
    
    return (
        <div className="landing-page">
        {materialID ? (
            <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
    <UserPage showNavBar={false} showAccountNavigation={false} sample={true}/>
    <Button type="submit" onClick={goToRegister} variant="contained" sx={{ backgroundColor: '#f9c571', '&:hover': { backgroundColor: '#E04A2F' } , marginTop :'40px'}}>
          Register for Free
         </Button> 
    <h3 className="heading-3"><span className="text-span-3">
    To Learn the Rest of Your Slides! 
  </span></h3>
  </div>
) : (
    isLoading ? (
        <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
            <CircularProgress />
            <Typography variant="subtitle1" sx={{ color: 'black', textAlign: 'center' }}>
                Processing document...
            </Typography>
        </div>
    ) : (
        <div className='div-block-10' style={{display:"flex", flexDirection:"column", alignItems: "center", gap: '60px'
           , marginBottom: '60px', marginTop: '60px'}}>
            <h2 className="heading-9"><span className="text-span-2">Try It with Your Own Notes!</span></h2>
            <input
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            <AddMaterialButton onClick={addMaterial} />
        </div>
    )
)}
        </div>
    );
    };
    
export default LandingSample