import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from "./output_logo3.png";
import './NavBar.css';

const NavBar = ({ loggedIn = false }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Placeholder for logout functionality
    navigate('/'); // Navigate to the home page after logout
  };

  return (
    <AppBar className="navbar" sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.6)'}}>
      <Toolbar className="toolbar">
        <Box className="logo-box" onClick={() => navigate('/')}>
          <img src={Logo} alt="LearnNavigator Logo" className="logo" />
          <Typography variant="h6" component="div" className="nav-title" align="left">
            Learn<wbr />Navigator<wbr />.io
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} /> {/* Spacer to push buttons to the right */}
        <Box className="nav-buttons">
          {loggedIn ? (
            <></>
          ) : (
            <>
            
            <Button
      type="submit"
      onClick={() => navigate('/login')}
      variant="contained"
      sx={{
        background: 'linear-gradient(45deg, #006547, #004065)',
        boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)', // Multiple shadows for even effect
        '&:hover': {
          background: 'linear-gradient(45deg, #006547, #004065)', // Maintain the gradient background on hover
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)', // Glowing effect with multiple shadows
        },
        margin:' 10px',
        color: '#ffffff', // Ensure text color is white
      }}
    >Login</Button>
               <Button
      type="submit"
      onClick={() => navigate('/register')}
      variant="contained"
      sx={{
        background: 'linear-gradient(45deg, #006547, #004065)',
        boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)', // Multiple shadows for even effect
        '&:hover': {
          background: 'linear-gradient(45deg, #006547, #004065)', // Maintain the gradient background on hover
          boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)', // Glowing effect with multiple shadows
        },
        margin:' 10px',
        color: '#ffffff', // Ensure text color is white
      }}
    >Register</Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
