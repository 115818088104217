import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal } from '@mui/material';
import { updateMaterialDetails } from '../services/materialService';

function CreateStudyPlanModal({materialId, open, handleClose, examDate, setExamDate, studyDaysPerWeek, setStudyDaysPerWeek }) {
  const [internalExamDate, setInternalExamDate] = useState(null);
  const [internalStudyDaysPerWeek, setInternalStudyDaysPerWeek] = useState(2);
  const [secondModalOpen, setSecondModalOpen] = useState(false);

  const handleAccept = async (event) => {
    event.preventDefault();
    const updates = {
      examDate: internalExamDate,
      studyDaysPerWeek: parseInt(internalStudyDaysPerWeek, 10)
    };
    await updateMaterialDetails(materialId, updates);
    setExamDate(internalExamDate);
    setStudyDaysPerWeek(internalStudyDaysPerWeek);
    handleClose();
    setSecondModalOpen(true);
  };

  const handleFirstModalClose = () => {
    handleClose();
    setSecondModalOpen(true);
  };

  const handleSecondModalClose = () => {
    setSecondModalOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleFirstModalClose} sx={{ border: 'none' }}>
        <Box
          component="form"
          onSubmit={handleAccept}
          sx={{
            backgroundColor: '#f9dbac',
            position: 'absolute',
            borderRadius: '15px',
            border: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
          }}
        >
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
            <Typography sx={{ color: 'var(--color4)', fontSize: '1.5rem' }}>
              Create Study Plan
            </Typography>
            <Typography sx={{ color: 'var(--color4)', fontSize: '1rem', marginLeft: '10px' }}>
              Beta
            </Typography>
          </Box>
          <TextField
            label="Exam Date"
            type="date"
            value={internalExamDate}
            onChange={(e) => setInternalExamDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            required
            fullWidth
          />
          <TextField
            label="Study Days per Week"
            type="number"
            value={internalStudyDaysPerWeek}
            onChange={(e) => setInternalStudyDaysPerWeek(e.target.value)}
            required
            fullWidth
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleFirstModalClose}
              sx={{ borderRadius: '10px',
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  margin: '5px',
                  color: '#004065'
               }}
            >
              Continue without study plan
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                background: 'linear-gradient(45deg, #006547, #004065)',
                margin: '5px',
                boxShadow: '0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #006547, #004065)',
                  boxShadow: '0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)',
                },
                borderRadius: '10px'
              }}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={secondModalOpen} onClose={handleSecondModalClose} sx={{ border: 'none' }}>
      <Box
  sx={{
    backgroundColor: '#f9dbac',
    position: 'absolute',
    borderRadius: '15px',
    border: 'none',
    top: '50%',
    right: '300px',  // Position the modal 350px from the right
    transform: 'translateY(-50%)',  // Only translate vertically
    width: 400,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    overflow: 'visible'  // Ensure the arrow is visible
  }}
>
    <Button
      onClick={handleSecondModalClose}
      sx={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        color: '#004065',
        fontWeight: 'bold',
        cursor: 'pointer'
      }}
    >
      X
    </Button>
    <Typography sx={{ color: 'var(--color4)', fontSize: '1.2rem', mb: '16px' }}>
      Use the buttons on the right to mark your understanding. <br />
      Spaced repetition algorithm will help you remember them long-term.<br/>
      If you mark the concept as 'Understood', you will be quized!
    </Typography>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        right: '-20px',  // Position the arrow outside the modal box
        width: '0',
        height: '0',
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid #f9dbac'
      }}
    />
  </Box>
</Modal>

    </>
  );
}

export default CreateStudyPlanModal;
