import * as pdfjsLib from 'pdfjs-dist/webpack';
import SnippetExtractor from '../SnippetExtractor';
import { flattenDeep } from './snippetUtils';
import { convertSnippets } from './snippetUtils';

export async function processPdfPageAndExtractSnippets(fileData, pageNumber, scale){
    try {
      const loadingTask = pdfjsLib.getDocument(fileData);
      const pdfDoc = await loadingTask.promise;
      const page = await pdfDoc.getPage(pageNumber);
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: ctx, viewport }).promise;
      return canvas.toDataURL('image/png');
    } catch (error) {
      console.error("Error processing PDF page:", error);
      throw error;  // Re-throw to handle in calling function
    }
  }
  
export async function extractSnippetsFromImage(pageImage, bboxes, scale) {
    try {
      const snippetExtractor = new SnippetExtractor();
      const snippetDetails = await snippetExtractor.giveSnippets(pageImage, bboxes, scale);
      return snippetDetails.map(detail => detail.dataUrl);
    } catch (error) {
      console.error("Error extracting snippets:", error);
      return [];
    }
  }
  
export function setupCanvas(canvas, pdfPageElement) {
    const { width, height } = pdfPageElement.getBoundingClientRect();
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, width, height);  // Clear previous drawings
    return ctx;
  }
export function drawBlurredImage(ctx, bbox, snippetSrc, scale) {
    let [x0, y0, x1, y1] = bbox;
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = (x1 - x0) * devicePixelRatio;
    offscreenCanvas.height = (y1 - y0) * devicePixelRatio;
    const offscreenCtx = offscreenCanvas.getContext('2d');
    offscreenCtx.scale(devicePixelRatio, devicePixelRatio);
    offscreenCtx.fillStyle = "white";
    offscreenCtx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
    offscreenCtx.filter = 'blur(6px)';
  
    const img = new Image();
    img.onload = () => {
      offscreenCtx.drawImage(img, 0, 0, (x1 - x0), (y1 - y0));
      ctx.drawImage(offscreenCanvas, x0 * scale, y0 * scale, (x1 - x0) * scale, (y1 - y0) * scale);
    };
    img.src = snippetSrc;
  }
export function drawAllSnippets(canvas, pdfPageElement, snippets, scale, materialDetails, currentPage) {
    const ctx = setupCanvas(canvas, pdfPageElement);
    const currentBboxes = flattenDeep(convertSnippets(materialDetails.snippets, currentPage));
    currentBboxes.forEach((bbox, index) => {
      if (index < snippets.length) {
        drawBlurredImage(ctx, bbox, snippets[index], scale);
      }
    });
  }