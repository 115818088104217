import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, Divider, Switch, FormControlLabel, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import './SnippetBoard.css';
import { trackEvent } from '../tracking';
import './NavBar.css'
import { set } from 'react-ga';

const FullButton = React.forwardRef(({ text, completed, number, onClick, isActive, disabled, subText }, ref) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, marginBottom: '20px' }}>
    {number !== null && (<></>
      /*<Box sx={{
        color: "white",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        border: '2px solid var(--color4)',
        borderRadius: '4px',
        marginRight: '8px',
        backgroundColor: 'transparent',
      }}>
        <Typography sx={{ color: 'var(--color4)', fontSize: '0.875rem' }}>
          {number}
        </Typography>
      </Box>*/
    )}
   <Button
    ref={ref}
    variant="outlined"
    disabled={disabled}
    sx={{
      color: 'white',
      borderColor: 'var(--color4)',
      width: '100%',
      boxShadow: "0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)",
      background: isActive ? '#f9c571' : 'linear-gradient(45deg, #006547, #004065)',
      '&:hover': {
        boxShadow: "0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)"
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    onClick={onClick}
  >
    <Typography variant="body1">{text}</Typography>
    <Typography variant="caption" sx={{ fontSize: '10px' }}>
      {subText}
    </Typography>
  </Button>
    {completed ? <CheckCircleOutlineIcon color="success" style={{ color: '#f9c571' }} /> : <RadioButtonUncheckedIcon color="disabled" />}
  </Box>
));

const SnippetBoard = ({
  snippetsStatus, //important
  snippetsQuestion, //important
  updateStatus, //important
  nCurrentSnippets, //important
  lastLearnedDate,
  moveNext,
  moveBack,
  sample = false,
  snippetsOrder, //important
  setSnippetsOrder,//important
  moveSpecific,//important
  currentPage,//important
  hide,
  setHide,
  materialId,
  setTodaysSteps
}) => {
  const [showQuestion, setShowQuestion] = useState(false);
  const [question, setQuestion] = useState(null);
  const [status, setStatus] = useState(null);
  const [history, setHistory] = useState([]);
  const [isRevealed, setIsRevealed] = useState(false);
  //const [isRevealed, setIsRevealed] = useState(false);
  
  //const [currentQuestion, setCurrentQuestion] = useState(null);

  const difficultButtonRef = useRef(null);
  const easyButtonRef = useRef(null);
  const answeredButtonRef = useRef(null);
  const notRelevantButtonRef = useRef(null);

  // #0 Redundant - for printing currentpage, currentsnippet, snippetsorder on each rerender
  useEffect(()=>{
    //console.log('CurrentPage', currentPage);
    //console.log('CurrentSnippet', nCurrentSnippets);
    //console.log('SnippetsOrder', snippetsOrder);
  })

  // #1 sets up eventlistener for key presses on the beginning, or after the key press. the showQuestion dependency is redundant.
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === '1' && difficultButtonRef.current) {
        difficultButtonRef.current.click();
      } else if (event.key === '2' && easyButtonRef.current) {
        easyButtonRef.current.click();}
        else if(event.key === '3' && answeredButtonRef.current){
          answeredButtonRef.current.click();
        }  
       else if (event.key === '4' && notRelevantButtonRef.current) {
        notRelevantButtonRef.current.click();
      } else if (event.key === 'r') {
        updateStatus(1);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  },[]);

  //#2 change Status and CurrentQuestion on each snippetsSTatus, snippetsQuestion, currentPage, nCurrentSnippets change.
  useEffect(()=>{
    setStatus(snippetsStatus[currentPage - 1][nCurrentSnippets - 1]);
    //setCurrentQuestion(snippetsQuestion[currentPage - 1][nCurrentSnippets - 1]);
    setQuestion(snippetsQuestion[currentPage - 1][nCurrentSnippets-1]);
  }, [snippetsStatus, currentPage, nCurrentSnippets])


  async function updateDailySteps(materialId, stepsToBeUpdated, todaysDate) {
    const url = `${process.env.REACT_APP_API_URL}app/materials/${materialId}/update_daily_steps`;
    
    const requestData = {
        steps: stepsToBeUpdated,
        date: todaysDate
    };

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`, // Include the token in the header if needed
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data.daily_steps; // Return the updated daily_steps
    } catch (error) {
        console.error('Error updating daily steps:', error);
        throw error; // Rethrow the error so the caller can handle it if needed
    }
}


  const moveSnippetInOrder = (currentSnippetIndex, steps) => {
    const newSnippetsOrder = [...snippetsOrder];
    const [snippet] = newSnippetsOrder.splice(currentSnippetIndex, 1);
    const newIndex = Math.min(currentSnippetIndex + steps, newSnippetsOrder.length);
    newSnippetsOrder.splice(newIndex, 0, snippet);
    setSnippetsOrder(newSnippetsOrder);

    console.log('snippetStatus', snippetsStatus);

    // Print the new snippets order along with their statuses
    const snippetsWithStatus = newSnippetsOrder.slice(0, 50).map(([index0, index1]) => ({
        snippet: [index0, index1],
        status: snippetsStatus[index0 - 1][index1 - 1]
    }));
    const formattedSnippets = snippetsWithStatus.map(({ snippet, status }) => `Snippet: ${snippet} - Status: ${status}`).join('\n');
    //console.log('NEW SNIPPETS ORDER WITH STATUS\n', formattedSnippets);



    // Call the function to update the order in the backend
    updateSnippetsOrder(materialId, newSnippetsOrder);
};


const handleUndo = () => {
  if (history.length === 0) return; // Nothing to undo

  const lastState = history[history.length - 1];
  console.log('LAST STATE', lastState);
  setHistory(history.slice(0, -1)); // Remove the last state from history

  // Restore the last state
  setSnippetsOrder(lastState.snippetsOrder);
  moveSpecific(lastState.currentPage, lastState.nCurrentSnippets);
  updateStatus(lastState.snippetsStatus);
}
  
  const updateStatusLocally = (index) => {
    setIsRevealed(false);
    setShowQuestion(false);
    const oldFirstSnippetIndex = snippetsOrder.findIndex(snippet => snippetsStatus[snippet[0] - 1][snippet[1] - 1] !== 5);
    let oldFirstSnippet = snippetsOrder[0];
    let newFirstSnippet = snippetsOrder[1];
    let oldStatus = snippetsStatus[oldFirstSnippet[0]-1][oldFirstSnippet[1]-1]
    let nextStatus = snippetsStatus[newFirstSnippet[0] - 1][newFirstSnippet[1] - 1];
    // Check if a snippet with a non-5 status exists
    if (oldFirstSnippetIndex !== -1) {
      oldFirstSnippet = snippetsOrder[oldFirstSnippetIndex];
      console.log('oldFirstSnippet', oldFirstSnippet);
      console.log('snippetsStatus', snippetsStatus);
      // Find the second snippet with a status other than 5 (starting from the next index)

      const newFirstSnippetIndex = snippetsOrder.findIndex((snippet, currentIndex) => currentIndex > oldFirstSnippetIndex && snippetsStatus[snippet[0] - 1][snippet[1] - 1] !== 5);

      if (newFirstSnippetIndex !== -1) {
        newFirstSnippet = snippetsOrder[newFirstSnippetIndex];
        console.log('newFirstSnippet', newFirstSnippet);
        nextStatus = snippetsStatus[newFirstSnippet[0] - 1][newFirstSnippet[1] - 1];
        // ... rest of your code using nextStatus, etc.
          newFirstSnippet = snippetsOrder[newFirstSnippetIndex];
          console.log('newFirstSnippet', newFirstSnippet);
          nextStatus = snippetsStatus[newFirstSnippet[0] - 1][newFirstSnippet[1] - 1];
      } else {
        console.log('No second snippet with non-5 status found');
       newFirstSnippet = snippetsOrder[1];
          console.log('No second snippet with non-5 status found');
          newFirstSnippet = snippetsOrder[1];
      }
  } else {
      console.log('No snippet with non-5 status found');
      oldFirstSnippet = snippetsOrder[0];}

  setHistory((prevHistory) => [
      ...prevHistory,
      {
          snippetsOrder: [...snippetsOrder],
          snippetsStatus: JSON.parse(JSON.stringify(snippetsStatus))
      }
  ]);
  console.log('SetHistory');
  updateStatus(index, false, oldFirstSnippet[0], oldFirstSnippet[0]);
  if (index === 2) {
      moveSnippetInOrder(0, 7);
  } else if (index === 3) {
      moveSnippetInOrder(0, 15);
  } else if (index === 4) {
      moveSnippetInOrder(0, 20);
    } else if(index === 5) {
      moveSnippetInOrder(0, 10000); //TODO: This should be improved - right now it just shifts not relevant snippets to the end
    }
    console.log('movedSnippetinOrder')
    const step = index-1; //for 4 -> 3, for 3 ->2, for 2->1
    const deltaStep = index-oldStatus;

    const todaysDate = new Date().toISOString().split('T')[0]; // Format date as YYYY-MM-DD

  updateDailySteps(materialId, deltaStep, todaysDate)
      .then(updatedDailySteps => {
          setTodaysSteps(updatedDailySteps);
      })
      .catch(error => {
          console.error('Failed to update daily steps:', error);
          // Handle the error, e.g., show an error message to the user
      });
      console.log('UpdatedDailySteps');
  if (nextStatus === 3 || nextStatus === 4) {
      setQuestion(snippetsQuestion[newFirstSnippet[0] - 1][newFirstSnippet[1] - 1]);
      console.log('MoveSpecific', newFirstSnippet[0], newFirstSnippet[1]);
      moveSpecific(newFirstSnippet[0], newFirstSnippet[1]);
      setShowQuestion(true);
      setHide(true);
  } else if (nextStatus === 5) {
    console.log('MoveSpecific', newFirstSnippet[0], newFirstSnippet[1]);
      moveSpecific(newFirstSnippet[0], newFirstSnippet[1]);
    }
     else {
      console.log('MoveSpecific', newFirstSnippet[0], newFirstSnippet[1]);
      moveSpecific(newFirstSnippet[0], newFirstSnippet[1]);
  }
};
  
  const updateSnippetsOrder = async (materialId, newSnippetsOrder) => {
    const url = `${process.env.REACT_APP_API_URL}app/materials/${materialId}/update-snippets-order`; // Adjust the endpoint to your actual API path
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ snippetsOrder: newSnippetsOrder }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Snippets order updated successfully:', data);
    } catch (error) {
        console.error('Error updating snippets order:', error);
    }
  }

  const handleRevealConcept = () => {
    const nextSnippet = snippetsOrder[1];
    if (nextSnippet) {
      // Move to the specific snippet first
     // moveSpecific(nextSnippet[0], nextSnippet[1]);
  
      // Then update the state to show the question
      setHide(false);
      setIsRevealed(true);
    }
  };


  

const renderButtons = (status) => {
    const difficultButtonText = 'Difficult 🤨';
    const easyButtonText = 'Understood 😏';
    const answeredButtonText = 'Answered 🧠';
    const difficultButtonSubText = 'Review in 7 concepts';
    const easyButtonSubText = 'Review in 15 concepts';
    const answeredButtonSubText = 'Review in 20 concepts';

    let buttons = [];

    if ((!hide) && (status == 0 || status === 1 || status === 2 || status === 5)) {
        buttons.push(
            <FullButton
                ref={difficultButtonRef}
                key={1}
                text={difficultButtonText}
                subText={difficultButtonSubText}
                completed={2 <= status && status !== 5}
                number={1}
                onClick={() => updateStatusLocally(2)}
                isActive={difficultButtonText === question}
            />
        );

        buttons.push(
            <FullButton
                ref={easyButtonRef}
                key={3}
                text={easyButtonText}
                subText={easyButtonSubText}
                completed={3 <= status && status !== 5}
                number={2}
                onClick={() => updateStatusLocally(3)}
                isActive={easyButtonText === question}
            />
        );
        buttons.push(
            <Divider sx={{ borderColor: 'var(--color4)', marginBottom: '16px' }} />
        );
        buttons.push(
            <FullButton
                ref={notRelevantButtonRef}
                key={5}
                text={'Not Relevant 🤡'}
                completed={status === 5}
                number={4}
                onClick={() => updateStatusLocally(5)}
                isActive={'Not Relevant' === question}
            />
        );
    } else if (!hide && (status === 3 || status === 4)) {
        buttons.push(
            <FullButton
                ref={difficultButtonRef}
                key={1}
                text={difficultButtonText}
                subText={difficultButtonSubText}
                completed={2 <= status && status !== 5}
                number={1}
                onClick={() => updateStatusLocally(2)}
                isActive={difficultButtonText === question}
            />
        );
        buttons.push(
            <FullButton
                ref={easyButtonRef}
                key={3}
                text={easyButtonText}
                subText={easyButtonSubText}
                completed={3 <= status && status !== 5}
                number={2}
                onClick={() => updateStatusLocally(3)}
                isActive={easyButtonText === question}
            />
        );

        buttons.push(
            <FullButton
                ref={answeredButtonRef}
                key={4}
                text={answeredButtonText}
                subText={answeredButtonSubText}
                completed={4 <= status && status !== 5}
                number={3}
                onClick={() => updateStatusLocally(4)}
                isActive={answeredButtonText === question}
            />
        );
        buttons.push(
            <Divider sx={{ borderColor: 'var(--color4)', marginBottom: '16px' }} />
        );
        buttons.push(
            <FullButton
                ref={notRelevantButtonRef}
                key={5}
                text={'Not Relevant 🤡'}
                completed={status === 5}
                number={4}
                onClick={() => updateStatusLocally(5)}
                isActive={'Not Relevant' === question}
            />
        );
    } else if (!showQuestion) {
        buttons.push(<Typography variant="caption" sx={{ color: 'var(--color4)', width: '100%', textAlign: 'center', fontSize: '18px' }}>
            It seems there are no concepts to learn on this page. Maybe on the next one?
        </Typography>);
    }

    return <>{buttons}</>;
};


  return (
    <Box className="snippet-board" sx={{ padding: 2 }}>
      <Box className="switches">
        <Typography variant="h3" sx={{ color: 'var(--color4)', fontSize: '1.5rem', marginBottom: '16px' }}>
          Current Concept 🧩
        </Typography>
        <Divider sx={{ borderColor: 'var(--color4)', marginBottom: '16px' }} />
        {showQuestion && (
          <div>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '80px',
                border: '2px solid var(--color4)',
                borderRadius: '4px',
                marginBottom: '20px',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  height: '20px',
                  padding: '8px 0',
                }}
              >
                <Typography variant="caption" sx={{ color: 'var(--color4)', width: '100%', textAlign: 'center' }}>
                  Question
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body2" sx={{ color: 'var(--color4)', padding: '8px', textAlign: 'center' }}>
                  {question}
                </Typography>
              </Box>
            </Box>
            {!isRevealed && ( // Add this condition to hide the button after it is clicked
      <Button
        variant="outlined"
        sx={{
          color: 'white',
          background: 'linear-gradient(45deg, #006547, #004065)',
          borderColor: 'var(--color4)',
          marginBottom: '10px',
          width: '100%',
          boxShadow: "0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3)",
          '&:hover': {
            background: 'linear-gradient(45deg, #006547, #004065)',
            boxShadow: "0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6)",
          },
        }}
        onClick={handleRevealConcept}
      >
        {"Reveal Concept"}
      </Button>
    )}
          </div>
        )}
      </Box>
      <Box className="buttons" sx={{ minWidth: '200px' }}>
        {renderButtons(status)}
       
        <Box className="nav-buttons">
          {/*<button className="nav-button" style={{ marginRight: '10px' }} onClick={handleUndo}>
            &lt;
          </button>*/}
        </Box>
      </Box>
      <Typography className="clarification" sx={{ color: 'var(--color4)', fontSize: '0.9rem', marginBottom: '10px' }}>
      LearnNavigator.io missed a concept? While it misses 2.8x less concepts than traditional flashcards, we continue to work hard on improving it!
      </Typography>
    </Box>
  );
};

export default SnippetBoard;

/*  const handleKeyUpdate = (index) => {
  updateStatus(index, false);
  if (index !== 1) handleRevealConcept();
};

const handleShowQuestionChange = () => {
const newValue = !showQuestion;
setShowQuestion(newValue);
if (!sample) {
  trackEvent('Switch', `Question Mode: turned ${newValue ? 'on' : 'off'}`);
} else {
  trackEvent('Switch', `Sample_Question Mode: turned ${newValue ? 'on' : 'off'}`);
}
};
  const handleRevealConcept = () => {
    if (!showQuestion) {
      moveNext();
    } else {
      if (!isRevealed) {
        setQuestion(nextQuestion);
        moveNext();
        setIsRevealed(true);
        setButtonText('Next Question');
      } else {
        setQuestion(nextQuestion);
        setIsRevealed(false);
        setButtonText('Reveal Concept');
      }
    }
  }; */