
import React from 'react';
import { Document, Page } from 'react-pdf';

function DocumentViewer({ modifiedPdf, currentPage, onLoadSuccess, possibleScales, SCALE }) {
  return (
    <div className="document">
      <Document
        file={modifiedPdf}
        onLoadSuccess={onLoadSuccess}
        className="blurred-pdf"
      >
        <div>
          <Page pageNumber={currentPage} scale={possibleScales[SCALE]} className="blurred-page" renderTextLayer={false} renderAnnotationLayer={false} />
          <canvas id="middleCanvas" className="middle-canvas"></canvas>
          <canvas id="overlayCanvas" className="overlay-canvas"></canvas>
        </div>
      </Document>
    </div>
  );
}

export default DocumentViewer;