import { extractSnippetStatuses, extractSnippetDates, extractSnippetQuestions } from "../utils/snippetUtils";

export async function fetchMaterialDetails(materialId, setMaterialDetails, modifiedPdf, setModifiedPdf, setTempModifiedPdf, query, setsnippetsStatus, setSnippetsOrder, setNumPages, setSnippetsDates, setSnippetsQuestion, setCurrentPage, setNCurrentSnippets, isLoading, setExamDate, setStudyDaysPerWeek) {
    try {
        const fetchUrl = `${process.env.REACT_APP_API_URL}app/materials/pdf/${materialId}`;
        const response = await fetch(fetchUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMaterialDetails(data); // Update state with fetched data

        const pdfUrl = data.pdfFileUrl;
        const pdfResponse = await fetch(pdfUrl);
        if (!pdfResponse.ok) {
            throw new Error('Network response was not ok fetching the PDF');
        }
        const pdfBlob = await pdfResponse.blob();

        if (modifiedPdf === null) {
            setModifiedPdf(pdfBlob);
        }
        setTempModifiedPdf(pdfBlob);

        if (!query.get('processed')) {
            setNumPages(data.snippets.length);
        }
        setsnippetsStatus(extractSnippetStatuses(data));

        // Use the order field from the data to set snippetsOrder
        const snippetsOrder = extractSnippetOrders(data);
        //console.log("SNIPPETS ORDER - loaded", snippetsOrder);
        setSnippetsOrder(snippetsOrder);
        if(snippetsOrder.length>0){
            setCurrentPage(snippetsOrder[0][0]);
        setNCurrentSnippets(snippetsOrder[0][1]);}

        setSnippetsDates(extractSnippetDates(data));
        setSnippetsQuestion(extractSnippetQuestions(data));

        if (data.examDate) {
            setExamDate(data.examDate);
        }
        if (data.studyDaysPerWeek) {
            setStudyDaysPerWeek(data.studyDaysPerWeek);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

function extractSnippetOrders(data) {
  let snippets = [];
  data.snippets.forEach(page => {
      page.snippets.forEach(snippet => {
          snippets.push({
              page: page.page,
              snippetNo: snippet.snippetNo,
              order: snippet.order
          });
      });
  });

  // Sort the snippets by their 'order' field
  snippets.sort((a, b) => a.order - b.order);

  // Create the ordered array of [page, snippetNo] pairs
  let snippetOrder = snippets.map(snippet => [snippet.page, snippet.snippetNo]);

  return snippetOrder;
}

export const updateMaterialDetails = async (materialId, updates) => {
    const url = `${process.env.REACT_APP_API_URL}app/materials/${materialId}/update`; // Update with your actual API endpoint
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updates),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Optionally update materialDetails state here if the response includes updated material details
    } catch (error) {
        console.error('Error updating material details:', error);
    }
};